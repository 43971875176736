import React  from 'react';
import './App.css';
import './Report.css';
import ReportLang from './ReportLang.json';
import ReportMainToolbar from './ReportMainToolbar.js';

import ReportOccupancy from './ReportOccupancy.js';
import ReportOccupancyProgress from './ReportOccupancyProgress.js';
import ReportBillList from './ReportBillList.js';
import ReportExpenses from './ReportExpenses.js';

class ReportMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			tab: "occupancy",
			dashboardID: 0,
		};
		
		this.setTab = this.setTab.bind(this);
		this.renderOccupancy = this.renderOccupancy.bind(this);
		this.renderBills = this.renderBills.bind(this);
		this.renderExpenses = this.renderExpenses.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}

	componentDidMount() {

		var dashboardID = this.props.dashboardID;
		if (dashboardID > 0) {
			//this.serverGetReportVariableList(dashboardID, currentYear, currentMonth);
		}
		
		this.setState({
			dashboardID: dashboardID
		});
	}
	
	componentDidUpdate() {

		var dashboardID1 = this.state.dashboardID;
		var dashboardID2 = this.props.dashboardID;
		
		if (dashboardID1 === dashboardID2) {
			return;
		}

		if (dashboardID2 > 0) {
			//this.serverGetReportVariableList(dashboardID2, selectedYear, selectedMonth);
		}
		
		this.setState({
			dashboardID: dashboardID2
		});
	}

	setTab(tab) {
		this.setState({
			tab: tab,
		});
	}
	
	renderOccupancy(tab) {
		return (
			<ReportOccupancy
				calcHelpersObj={this.props.calcHelpersObj}
				baseURLAPI={this.props.baseURLAPI}
				dashboardID={this.props.dashboardID}
				dashboardName={this.props.dashboardName}
				dashboardAppUserType={this.props.dashboardAppUserType}
				dashboardUserType={this.props.dashboardUserType}
				tab={tab}
				setTab={this.setTab}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				getMonthString={this.props.getMonthString}
				getMonthStringShort={this.props.getMonthStringShort}
			/>
		);
	}
	
	renderProgress() {
		return (
			<ReportOccupancyProgress
				calcHelpersObj={this.props.calcHelpersObj}
				baseURLAPI={this.props.baseURLAPI}
				dashboardID={this.props.dashboardID}
				dashboardName={this.props.dashboardName}
				dashboardAppUserType={this.props.dashboardAppUserType}
				dashboardUserType={this.props.dashboardUserType}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				getMonthString={this.props.getMonthString}
				getMonthStringShort={this.props.getMonthStringShort}
			/>
		);
	}
	
	renderBills() {
		return (
			<ReportBillList
				calcHelpersObj={this.props.calcHelpersObj}
				baseURLAPI={this.props.baseURLAPI}
				dashboardID={this.props.dashboardID}
				dashboardName={this.props.dashboardName}
				dashboardAppUserType={this.props.dashboardAppUserType}
				dashboardUserType={this.props.dashboardUserType}
				email={this.props.email}
				fullName={this.props.fullName}
				companyID={this.props.companyID}
				selectedYear={this.props.selectedYear}
				language={this.props.language}
				setTab={this.setTab}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				getMonthString={this.props.getMonthString}
				getTwoDigits={this.props.getTwoDigits}
				showToast={this.props.showToast}
				setSelectedYear={this.props.setSelectedYear}
			/>
		);
	}
	
	renderExpenses() {
		return (
			<ReportExpenses
				calcHelpersObj={this.props.calcHelpersObj}
				baseURLAPI={this.props.baseURLAPI}
				dashboardID={this.props.dashboardID}
				dashboardName={this.props.dashboardName}
				setTab={this.setTab}
				getLang={this.props.getLang}
				getEURString={this.props.getEURString}
				getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				getMonthString={this.props.getMonthString}
			/>
		);
	}

	renderTab() {
		
		var tab = this.state.tab;
		
		if ((tab === "occupancy") || (tab === "roomrevenue") || (tab === "rooms") || (tab === "roomprice")) {
			return this.renderOccupancy(tab);
		}
		
		if (tab === "progress") {
			return this.renderProgress();
		}
		
		if (tab === "bills") {
			return this.renderBills();
		}
		
		if (tab === "expenses") {
			return this.renderExpenses();
		}
	}

	render() {
		
		return (
			<div className="ReportMainTopContainer">
				<ReportMainToolbar
					dashboardAppUserType={this.props.dashboardAppUserType}
					dashboardUserType={this.props.dashboardUserType}
					dashboardID={this.props.dashboardID}
					dashboardName={this.props.dashboardName}
					dashboardVariableListJsonObj={this.state.dashboardVariableListJsonObj}
					tab={this.state.tab}
					setTab={this.setTab}
					getLang={this.props.getLang}
				/>
				{this.renderTab()}
			</div>
		);
	}
}

export default ReportMain;

