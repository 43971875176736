import React  from 'react';
import './App.css';
import './Report.css';
import { useNavigate } from "react-router-dom";
import ReportLang from './ReportLang.json';

import ToolbarPopupMenu from "./ToolbarPopupMenu.js"

class ReportMainToolbar extends React.Component {

	constructor(props) {

		super(props);
				
		this.state = {
			innerWidth: 0,
			innerHeight: 0,
			showPopupMenuYN: false,
		}
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.handleSelectReport = this.handleSelectReport.bind(this);
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.handlePopupLink = this.handlePopupLink.bind(this);
		this.closePopupMenu = this.closePopupMenu.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.renderRight = this.renderRight.bind(this);
	}
	
	componentDidMount() {
		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
	};
	
	handleSelectReport() {
		const navigate = this.props.navigate;
  		navigate("/selectdashboard");
	}
	
	handleSelectLink(tab) {
		this.props.setTab(tab);
	}
	
	handlePopupLink() {
		this.setState({showPopupMenuYN: true});
	}

	closePopupMenu() {
		this.setState({showPopupMenuYN: false});
	}
	
	renderLink(linkTitle, linkValue, key, selectedYN) {

		var classNameString = "ReportMainToolbarLink";
					
		if (selectedYN) {
			classNameString = "ReportMainToolbarSelectedLink";
		}
		
		return (
			<div 
				key={key}
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	renderPopupLink(linkTitle, variableNameArray) {

		var classNameString = "ReportMainToolbarSelectedLink";
		
		return (
			<div 
				key="popup-link"
				className={classNameString}
			>
				<div 
					className={classNameString}
					onClick={() => this.handlePopupLink()}
				>
					{linkTitle}
				</div>
				{this.state.showPopupMenuYN && (
					<ToolbarPopupMenu
						variableNameArray={variableNameArray}
						handleSelectLink={this.handleSelectLink}
						getLang={this.props.getLang}
						closePopupMenu={this.closePopupMenu}
					/>
				)}
			</div>
		);
	}
	
	renderRight() {

		var i;
		var variableName;
		var variableString;
		var jsxCode;
		var jsxArray = [];
		var skipVariableYN;
		var key1;
		var key2;
		var variableNameArray = [];
		
		var dashboardID = this.props.dashboardID;

		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;

		var showBillsYN = false;		
		var showExpensesYN = false;
		
		if (dashboardAppUserType === 99) {
			showBillsYN = true;
		}

		if (dashboardAppUserType >= 100) {
			showBillsYN = true;
		}
		
		if (dashboardAppUserType === 1) {
			if ((dashboardUserType === 3) || (dashboardUserType === 102)) {
				showBillsYN = true;
			}
			if ((dashboardUserType === 1002) || (dashboardUserType === 1003) || (dashboardUserType === 1004)) {
				showBillsYN = true;
			}
		}
		
		/*if ((dashboardAppUserType === 100) || (dashboardAppUserType === 100)) {
			showBillsYN = true;
		}*/

		var showPriceYN = false;
		if (dashboardAppUserType === 99) {
			showPriceYN = true;
		}
		if (dashboardAppUserType >= 100) {
			showPriceYN = true;
		}
		if (dashboardAppUserType === 1) {
			if (dashboardUserType < 1000) {
				showPriceYN = true;
			}
		}

		variableNameArray.push("occupancy");
		if (showPriceYN) {
			variableNameArray.push("roomrevenue");
			variableNameArray.push("rooms");
			variableNameArray.push("roomprice");
			variableNameArray.push("progress");
		} else {
			variableNameArray.push("rooms");
		}
		if (showBillsYN) {
			variableNameArray.push("bills");
		}
		if (showExpensesYN) {
			variableNameArray.push("expenses");
		}
		
		var displayPopupYN = false;
		
		var tab = this.props.tab;
		var selectedYN;
		
		if (displayPopupYN) {

			for(i=0; i<variableNameArray.length; i++) {
				
				variableName = variableNameArray[i];
				variableString = this.props.getLang(ReportLang, variableName);

				selectedYN = false;
				if (tab === variableName) {
					selectedYN = true;
				}

				if (selectedYN) {
					jsxCode = this.renderPopupLink(variableString, variableNameArray);
					jsxArray.push(jsxCode);
				}				
			}
			
			return jsxArray;
		}

		for(i=0; i<variableNameArray.length; i++) {
			
			if (i>0) {
				key1 = "a-" + i;
				jsxCode = (<div key={key1} className="ReportMainToolbarText">|</div>);
				jsxArray.push(jsxCode);
			}

			key2 = "b-" + i;

			variableName = 	variableNameArray[i];
			variableString = this.props.getLang(ReportLang, variableName);

			selectedYN = false;
			if (tab === variableName) {
				selectedYN = true;
			}

			jsxCode = this.renderLink(variableString, variableName, key2, selectedYN);
			jsxArray.push(jsxCode);
		}

		return jsxArray;
	}
	
	render() {
		
		var dashboardName = this.props.dashboardName;
		var dataTip = this.props.getLang(ReportLang, "selectReport");
		
		return (
			<div className="ReportMainToolbarContainer1">
				<div className="ReportMainToolbarLeft">
					<div 
						className="ReportMainToolbarLink"
						onClick={() => this.handleSelectReport()}
						data-tip={dataTip}
					>
						{dashboardName}
					</div>
				</div>
				<div className="ReportMainToolbarRight">
					<div className="ReportMainToolbarContainer2">
						{this.renderRight()}
					</div>
				</div>
			</div>
		);
	}
}

export default function ReportMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <ReportMainToolbar {...props} navigate={navigate} />;
}

