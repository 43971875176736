import React  from 'react';
import './App.css';
import './Report.css';
import ReportLang from './ReportLang.json';

import ReportOccupancyGraph from './ReportOccupancyGraph.js';

class ReportOccupancy extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			innerWidth: 0,
			innerHeight: 0,
			currentYear: 0,
			currentMonth: 0,
			jsonObj: null,
			averageRoomsMonthArray: null,
			averageRoomPriceMonthArray: null,
			averageReportRevenueMonthArray: null,
			totalRevenueMonthArray: null,
			totalRoomsMonthArray: null,
			dayCountMonthArray: null,
			averageRoomPriceYear: 0,
			averageRoomsYear: 0,
			occupancyRateYear: 0,
			totalRevenueYear: 0,
			totalRoomsYear: 0,
			totalRooms: 0,
			dayCountYear: 0,
			averageRoomsMonthArray2: null,
			averageRoomPriceMonthArray2: null,
			averageReportRevenueMonthArray2: null,
			totalRevenueMonthArray2: null,
			totalRoomsMonthArray2: null,
			dayCountMonthArray2: null,
			averageRoomsYear2: 0,
			occupancyRateYear2: 0,
			averageRoomPriceYear2: 0,
			totalRevenueYear2: 0,
			dashboardVariableListJsonObj: null,
			compareToYear: 0,
			compareAverageRoomsMonthArray: null,
			compareAverageRoomPriceMonthArray: null,
			compareTotalRevenueMonthArray: null,
			showPriceYN: false,
			dataFileSelected: "",
			dataFileListObj: null,
			dataFileJsonObj: null,
		};
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.serverGetDashboardVariableList = this.serverGetDashboardVariableList.bind(this);		
		this.serverDataConvertCsvToJson = this.serverDataConvertCsvToJson.bind(this);		
		this.calcRoomsSumMonth = this.calcRoomsSumMonth.bind(this);
		this.calcAverageMonthYear = this.calcAverageMonthYear.bind(this);
		this.onClickYear = this.onClickYear.bind(this);
		this.renderHeaderOneYear = this.renderHeaderOneYear.bind(this);
		this.renderHeaderAllYears = this.renderHeaderAllYears.bind(this);
		this.renderNoReport = this.renderNoReport.bind(this);
		this.renderHeader = this.renderHeader.bind(this);
		this.renderOneLine = this.renderOneLine.bind(this);		
		this.getItemMonthDay = this.getItemMonthDay.bind(this);
		this.renderOneMonth = this.renderOneMonth.bind(this);
		this.renderAllMonths = this.renderAllMonths.bind(this);		
		this.renderYearSummaryOneMonth = this.renderYearSummaryOneMonth.bind(this);
		this.renderYearSummary = this.renderYearSummary.bind(this);
		this.renderCompareToYear = this.renderCompareToYear.bind(this);
	}

	async componentDidMount() {

		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);
		var dateObj = new Date();
		var currentYear = dateObj.getFullYear();
		var currentMonth = dateObj.getMonth() + 1;
		this.setState({
			currentYear: currentYear,
			currentMonth: currentMonth,
		});

		var jsonObj = await this.serverDataConvertCsvToJson("current.csv");
		this.setState({jsonObj: jsonObj});
		var dashboardID = this.props.dashboardID;
		if (dashboardID > 0) {
			this.serverGetDashboardVariableList(dashboardID);
			this.serverDataGetFileList(dashboardID);
		}

		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;
		
		if (dashboardAppUserType === 99) {
			this.setState({
				showPriceYN: true,
			});
		}
		if (dashboardAppUserType >= 100) {
			this.setState({
				showPriceYN: true,
			});
		}

		if (dashboardAppUserType === 1) {
			if (dashboardUserType < 1000) {
				this.setState({
					showPriceYN: true,
				});
			}
		}
	}

	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
	};
	
	async serverGetDashboardVariableList(dashboardID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getdashboardvariablelist?dashboardid=" + dashboardID + "&variablefrequency=daily";
		
		const res = await fetch(url);
		const dashboardVariableListJsonObj = await res.json();
		
		var i;
		var itemObj;
		
		for(i=0; i<dashboardVariableListJsonObj.length; i++) {
			itemObj = dashboardVariableListJsonObj[i];
			itemObj.values = null;
		}

		this.setState({
			dashboardVariableListJsonObj: dashboardVariableListJsonObj,
		});
	}
	
	async serverDataConvertCsvToJson(filename) {
		
		var baseURLAPI = this.props.baseURLAPI;
		var dashboardID = this.props.dashboardID;
		var dataUrl = "/dashboard/" + dashboardID + "/roomsWholeYear/" + filename
		var url = baseURLAPI + "/dataconvertcsvtojson?url=" +  encodeURIComponent(dataUrl);
		
		var res = await fetch(url);
		var jsonObj = await res.json();

		if (filename === "current.csv") {
			this.calcAverageMonthYear(jsonObj, true);
		} else {
			this.calcAverageMonthYear(jsonObj, false);
		}

		return jsonObj;
	}
	
	async serverDataGetFileList(dashboardID) {

		var baseURLAPI = this.props.baseURLAPI;
		var dataUrl = "/dashboard/" + dashboardID + "/roomsWholeYear"
		var url = baseURLAPI + "/datagetfilelist?url=" +  encodeURIComponent(dataUrl);
		
		var jsonObj = null;
		
		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {
		}

		this.setState({dataFileListObj: jsonObj});
	}
	
	calcRoomsSumMonth(jsonObj, month1) {

		var i;
		var itemObj;
		var dayCount = 0;
		var rooms;
		var roomsSum = 0;
		var revenue;
		var revenueSum = 0;

		for(i=1; i<=31; i++) {
			itemObj = this.getItemMonthDay(jsonObj, month1, i);
			if (itemObj !== null) {
				rooms = parseInt(itemObj.rooms);
				revenue = parseFloat(itemObj.revenue);
				roomsSum = roomsSum + rooms;
				revenueSum = revenueSum + revenue;
				dayCount ++;
			}
		}

		return [roomsSum, revenueSum, dayCount]
	}

	calcAverageMonthYear(jsonObj, currentYN) {
		
		if (jsonObj === null) {
			return;
		}

		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardID = this.props.dashboardID;
		var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);
		var i;
		var itemObj;
		var averageRoomsMonthArray = [];
		var averageRoomPriceMonthArray = [];
		var averageReportRevenueMonthArray = [];
		var totalRevenueMonthArray = [];
		var totalRoomsMonthArray = [];
		var dayCountMonthArray = [];
		var roomsSumMonth;
		var revenueSumMonth;
		var dayCountMonth;
		var revenue;
		var roomsPerDay;
		var roomPrice;
		var roomsSumYear = 0;
		var dayCountYear = 0;
		var dayCountYear1 = 0;
		var dayCountYear2 = 0;
		var averageReportRevenue;
		var totalRevenueYear = 0;
		var totalRoomsYear = 0;
		var dataArray = jsonObj.data;
		
		// niksa
		dayCountYear1 = dataArray.length;
		for(i=0; i<dataArray.length; i++) {
			itemObj = dataArray[i];
			revenue = parseFloat(itemObj.revenue);
			roomPrice = parseFloat(itemObj.roomPrice);
			roomsPerDay = parseFloat(itemObj.rooms);
			totalRoomsYear = totalRoomsYear + roomsPerDay;
			totalRevenueYear = totalRevenueYear + revenue;
		}
		
		for(i=1; i<=12; i++) {
			[roomsSumMonth, revenueSumMonth, dayCountMonth] = this.calcRoomsSumMonth(jsonObj, i);
			roomsSumYear = roomsSumYear + roomsSumMonth;
			if (revenueSumMonth > 0) {
				dayCountYear2 = dayCountYear + dayCountMonth;
			}
			roomsPerDay = 0;
			averageReportRevenue = 0;
			if (dayCountMonth > 0) {
				roomsPerDay = roomsSumMonth / dayCountMonth;
				averageReportRevenue = revenueSumMonth / dayCountMonth;
			}
			if (roomsSumMonth === 0) {
				roomPrice = 0;
			} else {
				roomPrice = revenueSumMonth / roomsSumMonth;
			}
			averageRoomsMonthArray.push(roomsPerDay);
			averageRoomPriceMonthArray.push(roomPrice);
			totalRevenueMonthArray.push(revenueSumMonth);
			totalRoomsMonthArray.push(roomsSumMonth);
			dayCountMonthArray.push(dayCountMonth);
			averageReportRevenueMonthArray.push(averageReportRevenue);
		}
		
		dayCountYear = dayCountYear1;
		if ((dashboardID === 4) || (dashboardID === 5)) {
			dayCountYear = dayCountYear2;
		}
		
		if (dayCountYear === 0) {
			dayCountYear = 1;
		}
		var averageRoomsYear = totalRoomsYear / dayCountYear;
		var averageRoomPriceYear = 0;
		if (totalRoomsYear > 0) {
			averageRoomPriceYear = totalRevenueYear / totalRoomsYear;
		}
		var occupancyRateYear = 100 * averageRoomsYear / totalRooms;

		if (currentYN) {
			this.setState({
				averageRoomsMonthArray: averageRoomsMonthArray,
				averageRoomPriceMonthArray: averageRoomPriceMonthArray,
				averageReportRevenueMonthArray: averageReportRevenueMonthArray,
				totalRevenueMonthArray: totalRevenueMonthArray,
				totalRoomsMonthArray: totalRoomsMonthArray,
				dayCountMonthArray: dayCountMonthArray,
				averageRoomsYear: averageRoomsYear,
				occupancyRateYear: occupancyRateYear,
				averageRoomPriceYear: averageRoomPriceYear,
				totalRevenueYear: totalRevenueYear,
				totalRoomsYear: totalRoomsYear,
				totalRooms: totalRooms,
				dayCountYear: dayCountYear,
			});
		} else {
			this.setState({
				averageRoomsMonthArray2: averageRoomsMonthArray,
				averageRoomPriceMonthArray2: averageRoomPriceMonthArray,
				averageReportRevenueMonthArray2: averageReportRevenueMonthArray,
				totalRevenueMonthArray2: totalRevenueMonthArray,
				totalRoomsMonthArray2: totalRoomsMonthArray,
				dayCountMonthArray2: dayCountMonthArray,
				averageRoomsYear2: averageRoomsYear,
				occupancyRateYear2: occupancyRateYear,
				averageRoomPriceYear2: averageRoomPriceYear,
				totalRevenueYear2: totalRevenueYear,
				totalRoomsYear2: totalRoomsYear,
			});
		}
	}
	
	async onClickYear(year) {
		
		var compareToYear = this.state.compareToYear;

		if (year === compareToYear) {
			compareToYear = 0;
		} else {
			compareToYear = year;
		}

		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardVariableListJsonObj = this.state.dashboardVariableListJsonObj;
		var currentYear = this.state.currentYear;
		var currentMonth = this.state.currentMonth;
		var totalRooms = this.state.totalRooms;
		var dashboardVariableID;
		var jsonObj;
		var month;
		var monthValue;
		var dayValue;
		var daysInMonth;
		var compareAverageRoomsMonthArray = [];
		var compareAverageRoomPriceMonthArray = [];
		var compareTotalRevenueMonthArray = [];
		var compareTotalRevenueYear = 0;
		var compareDayCountYear = 0;
		var roomsSumYear = 0;

		if (compareToYear > 0) {
			
			dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, "rooms");
			jsonObj = await calcHelpersObj.serverGetDashboardValueYear(dashboardVariableID, year);
			for(month=0; month<12; month++) {
				monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, "rooms", jsonObj);
				roomsSumYear = roomsSumYear + monthValue;
				daysInMonth = calcHelpersObj.getDaysInMonth(compareToYear, month);
				dayValue = monthValue / daysInMonth;
				compareAverageRoomsMonthArray.push(dayValue);
				if (monthValue > 0) {
					compareDayCountYear = compareDayCountYear + daysInMonth;
				}
			}

			dashboardVariableID = calcHelpersObj.getDashboardVariableIDFromVariableName(dashboardVariableListJsonObj, "roomprice");
			jsonObj = await calcHelpersObj.serverGetDashboardValueYear(dashboardVariableID, year);
			for(month=0; month<12; month++) {
				monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, "roomprice", jsonObj);
				compareAverageRoomPriceMonthArray.push(monthValue);
			}

			jsonObj = await calcHelpersObj.calcRoomRevenueValueYear(dashboardVariableListJsonObj, year);
			for(month=0; month<12; month++) {
				monthValue = calcHelpersObj.getMonthValue(currentYear, currentMonth, year, month+1, "roomrevenue", jsonObj);
				compareTotalRevenueMonthArray.push(monthValue);
				compareTotalRevenueYear = compareTotalRevenueYear + monthValue;
			}

			if (compareDayCountYear === 0) {
				compareDayCountYear = 1;
			}
			var compareAverageRoomsYear = roomsSumYear / compareDayCountYear;
			var compareAverageRoomPriceYear = 0;
			if (roomsSumYear > 0) {
				compareAverageRoomPriceYear = compareTotalRevenueYear / roomsSumYear;
			}
			var compareOccupancyRateYear = 100 * compareAverageRoomsYear / totalRooms;
		}

		this.setState({
			compareToYear: compareToYear,
			compareAverageRoomsMonthArray: compareAverageRoomsMonthArray,
			compareAverageRoomPriceMonthArray: compareAverageRoomPriceMonthArray,
			compareTotalRevenueMonthArray: compareTotalRevenueMonthArray,
			compareTotalRevenueYear: compareTotalRevenueYear,
			compareAverageRoomsYear: compareAverageRoomsYear,
			compareAverageRoomPriceYear: compareAverageRoomPriceYear,
			compareOccupancyRateYear: compareOccupancyRateYear,
			compareDayCountYear: compareDayCountYear,
		});
	}
	
	renderHeaderOneYear(year, selected) {
		
		var className = "";
		
		if (selected) {
			className = "ReportOccupancyYearOneYearSelected";
		} else {
			className = "ReportOccupancyYearOneYear";
		}
		
		return (
			<div key={year} className={className} onClick={() => this.onClickYear(year)}>{year}</div>
		);
	}

	renderHeaderAllYears() {
		
		var innerWidth = this.state.innerWidth;
		
		var yearCount = 10;
		if (innerWidth < 1000) {
			yearCount = 5;
		}

		var currentYear = this.state.currentYear;
		var compareToYear = this.state.compareToYear;
		var i;
		var rows = [];
		var year;
		var selected;

		for(i=0; i<yearCount; i++) {
			year = currentYear - yearCount + i;
			if (year === compareToYear) {
				selected = true;
			} else {
				selected = false;
			}
			rows.push(this.renderHeaderOneYear(year, selected));
		}

		return (
			<div className="ReportOccupancyYearContainer">{rows}</div>
		);
	}
	
	renderNoReport() {

		var dashboardName = this.props.dashboardName;
		var string1 = this.props.getLang(ReportLang, "noOccupancyReportForProperty");
		var string2 = string1 + " " + dashboardName;

		return (
			<div>
				<div style={{height: "20px"}}></div>
				{string2}
				<div style={{height: "500px"}}></div>
			</div>
		);
	}

	renderHeader() {

		var jsonObj = this.state.jsonObj;
		var headerObj = jsonObj.header;
		var unixTimeSec = headerObj.unixTimeSec;
		var timeString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);

		return (
			<div>
				{timeString}
			</div>
		);
	}

	renderOneLine(index, itemObj1, itemObj2) {

		var totalRooms = this.state.totalRooms;
		var year = itemObj1.year;
		var month = itemObj1.month;
		var day = itemObj1.day;
		var rooms = itemObj1.rooms;
		var revenue = itemObj1.revenue;
		var roomPrice = itemObj1.roomPrice;
		var dateString = day + "." + month + "." + year;
		var revenueString = this.props.getEURString(revenue, 0);
		var roomPriceString = Math.round(roomPrice);
		var showPriceYN = this.state.showPriceYN;
		var occupancyRate = Math.round(100 * rooms / totalRooms);

		var rooms2;
		var revenue2;
		var roomPrice2;
		var revenueString2;
		var roomPriceString2;
		var occupancyRate2;

		if (itemObj2 !== null) {
			rooms2 = itemObj2.rooms;
			revenue2 = itemObj2.revenue;
			roomPrice2 = itemObj2.roomPrice;
			revenueString2 = this.props.getEURString(revenue2, 0);
			roomPriceString2 = Math.round(roomPrice2);
			occupancyRate2 = Math.round(100 * rooms2 / totalRooms);
		}

		if (showPriceYN) {
			if (itemObj2 === null) {
				return (
					<div key={index} className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft">{dateString}</div>
						<div className="ReportOccupancyBoxRight">{rooms} x {roomPriceString} = <b>{revenueString}</b> - {occupancyRate}%</div>
					</div>
				);
			} else {
				return (
					<div key={index} className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft">{dateString}</div>
						<div className="ReportOccupancyBoxRight">
							{rooms} x {roomPriceString} = <b>{revenueString}</b> - {occupancyRate}%
							<p className="ReportOccupancyBoxRightLight">{rooms2} x {roomPriceString2} = <b>{revenueString2}</b> - {occupancyRate2}%</p>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div key={index} className="ReportOccupancyBoxLineContainer">
					<div className="ReportOccupancyBoxLeft">{dateString}</div>
					<div className="ReportOccupancyBoxRight">{rooms} - {occupancyRate}%</div>
				</div>
			);
		}
	}
	
	getItemMonthDay(jsonObj, month1, day1) {
		
		if (jsonObj === null) {
			return null;
		}

		var dataObj = jsonObj.data;
		var i;
		var itemObj;
		var month2;
		var day2;
		
		for(i=0; i<dataObj.length; i++) {
			itemObj = dataObj[i];
			month2 = itemObj.month;
			day2 = itemObj.day;
			if ((month1 === month2) && (day1 === day2)) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	renderOneMonth(month1, mobileYN) {
		
		var i;
		var itemObj1;
		var itemObj2;
		var jsxCode;
		var jsxArray = [];
		var jsonObj = this.state.jsonObj;
		var dataFileJsonObj = this.state.dataFileJsonObj;
		
		for(i=1; i<=31; i++) {
			itemObj1 = this.getItemMonthDay(jsonObj, month1, i);
			itemObj2 = this.getItemMonthDay(dataFileJsonObj, month1, i);
			if (itemObj1 !== null) {
				jsxCode = this.renderOneLine(i, itemObj1, itemObj2);
				jsxArray.push(jsxCode);
			}
		}
		
		var currentYear = this.state.currentYear;
		var totalRooms = this.state.totalRooms;
		var averageRoomsMonthArray = this.state.averageRoomsMonthArray;
		var totalRevenueMonthArray = this.state.totalRevenueMonthArray;
		var totalRoomsMonthArray = this.state.totalRoomsMonthArray;
		var dayCountMonthArray = this.state.dayCountMonthArray;
		var roomsPerDay = averageRoomsMonthArray[month1-1];
		var occupancyRate = Math.round(100 * roomsPerDay / totalRooms);
		var totalRevenue = totalRevenueMonthArray[month1-1];
		var rooms = totalRoomsMonthArray[month1-1];
		var dayCountMonth = dayCountMonthArray[month1-1];
		var showPriceYN = this.state.showPriceYN;
		
		var averageRoomsMonthArray2 = this.state.averageRoomsMonthArray2;
		var totalRevenueMonthArray2 = this.state.totalRevenueMonthArray2;
		var totalRoomsMonthArray2 = this.state.totalRoomsMonthArray2;
		var dayCountMonthArray2 = this.state.dayCountMonthArray2;

		if (dataFileJsonObj === null) {
			if (totalRevenue === 0) {
				return null;
			}
		}

		var roomPrice = 0;
		if (rooms > 0) {
			roomPrice = totalRevenue / rooms;
		}

		var monthString = this.props.getMonthString(month1);
		var totalRevenueString = this.props.getEURString(totalRevenue, 0);

		var trevparDay = totalRevenue / totalRooms / dayCountMonth;
		var trevparMonth = totalRevenue / totalRooms;
		
		var trevparString = this.props.getEURString(trevparDay, 0) + " (" + this.props.getEURString(trevparMonth, 0) + ")";

		if (showPriceYN) {
			if (dataFileJsonObj === null) {
				return (
					<div key={month1}>
						{mobileYN && (<div style={{height: "20px"}}></div>)}
						<div className="ReportOccupancyOneBox">
							<div className="ReportOccupancyBoxTitle">{monthString} {currentYear}</div>
							{jsxArray}
							<div className="ReportOccupancyBoxLineContainer">
								<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
								<div className="ReportOccupancyBoxRight">{Math.round(roomsPerDay)} x {Math.round(roomPrice)} - {occupancyRate}%</div>
							</div>
							<div className="ReportOccupancyBoxLineContainer">
								<div className="ReportOccupancyBoxLeft"><b>RRevPAR:</b></div>
								<div className="ReportOccupancyBoxRight">{trevparString}</div>
							</div>
							<div className="ReportOccupancyBoxLineContainer">
								<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "total")}:</b></div>
								<div className="ReportOccupancyBoxRight">{totalRevenueString}</div>
							</div>				
							<div style={{height: "5px"}}></div>
						</div>
					</div>
				);
			} else {
				var roomsPerDay2 = averageRoomsMonthArray2[month1-1];
				var occupancyRate2 = Math.round(100 * roomsPerDay2 / totalRooms);
				var totalRevenue2 = totalRevenueMonthArray2[month1-1];
				var rooms2 = totalRoomsMonthArray2[month1-1];
				var dayCountMonth2 = dayCountMonthArray2[month1-1];
				var roomPrice2 = 0;
				if (rooms2 > 0) {
					roomPrice2 = totalRevenue2 / rooms2;
				}
				var totalRevenueString2 = this.props.getEURString(totalRevenue2, 0);
				var trevparDay2 = totalRevenue2 / totalRooms / dayCountMonth2;
				var trevparMonth2 = totalRevenue2 / totalRooms;
				var trevparString2 = this.props.getEURString(trevparDay2, 0) + " (" + this.props.getEURString(trevparMonth2, 0) + ")";
				return (
					<div key={month1}>
						{mobileYN && (<div style={{height: "20px"}}></div>)}
						<div className="ReportOccupancyOneBox">
							<div className="ReportOccupancyBoxTitle">{monthString} {currentYear}</div>
							{jsxArray}
							<div className="ReportOccupancyBoxLineContainer">
								<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
								<div className="ReportOccupancyBoxRight">
									{Math.round(roomsPerDay)} x {Math.round(roomPrice)} - {occupancyRate}%<br/>
									<p className="ReportOccupancyBoxRightLight">{Math.round(roomsPerDay2)} x {Math.round(roomPrice2)} - {occupancyRate2}%</p>
								</div>
							</div>
							<div className="ReportOccupancyBoxLineContainer">
								<div className="ReportOccupancyBoxLeft"><b>RRevPAR:</b></div>
								<div className="ReportOccupancyBoxRight">
									{trevparString}
									<p className="ReportOccupancyBoxRightLight">{trevparString2}</p>
								</div>
							</div>
							<div className="ReportOccupancyBoxLineContainer">
								<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "total")}:</b></div>
								<div className="ReportOccupancyBoxRight">
									{totalRevenueString}
									<p className="ReportOccupancyBoxRightLight">{totalRevenueString2}</p>
								</div>
							</div>
							<div style={{height: "5px"}}></div>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div key={month1}>
					{mobileYN && (<div style={{height: "20px"}}></div>)}
					<div className="ReportOccupancyOneBox">
						<div className="ReportOccupancyBoxTitle">{monthString} {currentYear}</div>
						{jsxArray}
						<div className="ReportOccupancyBoxLineContainer">
							<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
							<div className="ReportOccupancyBoxRight">{Math.round(roomsPerDay)} - {occupancyRate}%</div>
						</div>
						<div style={{height: "5px"}}></div>
					</div>
				</div>
			);
		}
	}
	
	renderAllMonths() {

		var innerWidth = this.state.innerWidth;
		
		var mobileYN = false;
		if (innerWidth < 1000) {
			mobileYN = true;
		}
		
		var i;
		var jsxCode;
		var jsxArray = [];
		
		for(i=1; i<=12; i++) {
			jsxCode = this.renderOneMonth(i, mobileYN);
			jsxArray.push(jsxCode);
		}

		if (mobileYN) {
			return (
				<center>
					{jsxArray}
				</center>
			);
		}

		return (
			<div className="ReportOccupancyBoxContainer">
				{jsxArray}
			</div>
		);
	}

	renderYearSummaryOneMonth(month1) {

		var showPriceYN = this.state.showPriceYN;

		var averageRoomsMonthArray = this.state.averageRoomsMonthArray;
		var averageRoomPriceMonthArray = this.state.averageRoomPriceMonthArray;
		var totalRevenueMonthArray = this.state.totalRevenueMonthArray;

		var totalRooms = this.state.totalRooms;
		var monthString = this.props.getMonthString(month1);
		var averageRoomsMonth = averageRoomsMonthArray[month1-1];
		var roomPrice = averageRoomPriceMonthArray[month1-1];
		var revenue = totalRevenueMonthArray[month1-1];
		var occupancyRate = Math.round(100 * averageRoomsMonth / totalRooms);
		var roomPriceString = Math.round(roomPrice);
		var revenueString = this.props.getEURString(revenue, 0);		

		var dataFileJsonObj = this.state.dataFileJsonObj;
		var averageRoomsMonthArray2 = this.state.averageRoomsMonthArray2;
		var averageRoomPriceMonthArray2 = this.state.averageRoomPriceMonthArray2;
		var totalRevenueMonthArray2 = this.state.totalRevenueMonthArray2;
		
		if (dataFileJsonObj === null) {
			if (revenue === 0) {
				return null;
			}
		}

		if (showPriceYN) {
			if (dataFileJsonObj === null) {
				return (
					<div key={month1} className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft">{monthString}</div>
						<div className="ReportOccupancyBoxRight">{Math.round(averageRoomsMonth)} x {roomPriceString} => <b>{revenueString}</b> - {occupancyRate}%</div>
					</div>
				)
			} else {
				var averageRoomsMonth2 = averageRoomsMonthArray2[month1-1];
				var roomPrice2 = averageRoomPriceMonthArray2[month1-1];
				var revenue2 = totalRevenueMonthArray2[month1-1];
				var occupancyRate2 = Math.round(100 * averageRoomsMonth2 / totalRooms);
				var roomPriceString2 = Math.round(roomPrice2);
				var revenueString2 = this.props.getEURString(revenue2, 0);		
				return (
					<div key={month1} className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft">{monthString}</div>
						<div className="ReportOccupancyBoxRight">
							{Math.round(averageRoomsMonth)} x {roomPriceString} => <b>{revenueString}</b> - {occupancyRate}%
							<p className="ReportOccupancyBoxRightLight">{Math.round(averageRoomsMonth2)} x {roomPriceString2} => <b>{revenueString2}</b> - {occupancyRate2}%</p>
						</div>
					</div>
				)
			}
		} else {
			return (
				<div key={month1} className="ReportOccupancyBoxLineContainer">
					<div className="ReportOccupancyBoxLeft">{monthString}</div>
					<div className="ReportOccupancyBoxRight">{Math.round(averageRoomsMonth)} - {occupancyRate}%</div>
				</div>
			)
		}
	}
	
	renderYearSummary() {

		var i;
		var jsxCode;
		var jsxArray = [];
		var currentYear = this.state.currentYear;
		var averageRoomsYear = this.state.averageRoomsYear;
		var occupancyRateYear = this.state.occupancyRateYear;
		var averageRoomPriceYear = this.state.averageRoomPriceYear;
		var totalRevenueYear = this.state.totalRevenueYear;
		var totalRevenueYearString = this.props.getEURString(totalRevenueYear, 0);
		var totalRooms = this.state.totalRooms;
		var totalRoomsYear = this.state.totalRoomsYear;
		var dayCountYear = this.state.dayCountYear;
		var trevparDay = totalRevenueYear / totalRooms / dayCountYear;
		var trevparYear = totalRevenueYear / totalRooms;
		var showPriceYN = this.state.showPriceYN;
		var dataFileJsonObj = this.state.dataFileJsonObj;
		var averageRoomsYear2 = this.state.averageRoomsYear2;
		var occupancyRateYear2 = this.state.occupancyRateYear2;
		var averageRoomPriceYear2 = this.state.averageRoomPriceYear2;
		var totalRevenueYear2 = this.state.totalRevenueYear2;
		var totalRoomsYear2 = this.state.totalRoomsYear2;
		
		var trevparString = this.props.getEURString(trevparDay, 0) + " (" + this.props.getEURString(trevparYear, 0) + ")";
		
		for(i=1; i<=12; i++) {
			jsxCode = this.renderYearSummaryOneMonth(i);
			jsxArray.push(jsxCode);
		}

		if (showPriceYN) {
			if (dataFileJsonObj === null) {		
				return (
					<div className="ReportOccupancyOneBox">
						<div className="ReportOccupancyBoxTitle">{this.props.getLang(ReportLang, "fullYear")} {currentYear}</div>
						{jsxArray}
						<div className="ReportOccupancyBoxLineContainer">
							<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
							<div className="ReportOccupancyBoxRight">{Math.round(averageRoomsYear)} x {Math.round(averageRoomPriceYear)} - {Math.round(occupancyRateYear)}%</div>
						</div>
						<div className="ReportOccupancyBoxLineContainer">
							<div className="ReportOccupancyBoxLeft"><b>RRevPAR:</b></div>
							<div className="ReportOccupancyBoxRight">{trevparString}</div>
						</div>
						<div className="ReportOccupancyBoxLineContainer">
							<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "total")}:</b></div>
							<div className="ReportOccupancyBoxRight">{totalRevenueYearString}</div>
						</div>
						<div style={{height: "5px"}}></div>
					</div>
				);
			} else {
				var trevparDay2 = totalRevenueYear2 / totalRooms / dayCountYear;
				var trevparYear2 = totalRevenueYear2 / totalRooms;
				var trevparString2 = this.props.getEURString(trevparDay2, 0) + " (" + this.props.getEURString(trevparYear2, 0) + ")";
				var totalRevenueYearString2 = this.props.getEURString(totalRevenueYear2, 0);
				return (
					<div className="ReportOccupancyOneBox">
						<div className="ReportOccupancyBoxTitle">{this.props.getLang(ReportLang, "fullYear")} {currentYear}</div>
						{jsxArray}
						<div className="ReportOccupancyBoxLineContainer">
							<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
							<div className="ReportOccupancyBoxRight">
								{Math.round(averageRoomsYear)} x {Math.round(averageRoomPriceYear)} - {Math.round(occupancyRateYear)}%
								<p className="ReportOccupancyBoxRightLight">{Math.round(averageRoomsYear2)} x {Math.round(averageRoomPriceYear2)} - {Math.round(occupancyRateYear2)}%</p>
							</div>
						</div>
						<div className="ReportOccupancyBoxLineContainer">
							<div className="ReportOccupancyBoxLeft"><b>RRevPAR:</b></div>
							<div className="ReportOccupancyBoxRight">
								{trevparString}
								<p className="ReportOccupancyBoxRightLight">{trevparString2}</p>
							</div>
						</div>
						<div className="ReportOccupancyBoxLineContainer">
							<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "total")}:</b></div>
							<div className="ReportOccupancyBoxRight">
								{totalRevenueYearString}
								<p className="ReportOccupancyBoxRightLight">{totalRevenueYearString2}</p>
							</div>
						</div>
						<div style={{height: "5px"}}></div>
					</div>
				);
			}
		} else {
			return (
				<div className="ReportOccupancyOneBox">
					<div className="ReportOccupancyBoxTitle">{this.props.getLang(ReportLang, "fullYear")} {currentYear}</div>
					{jsxArray}
					<div className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
						<div className="ReportOccupancyBoxRight">{Math.round(averageRoomsYear)} - {Math.round(occupancyRateYear)}%</div>
					</div>
					<div style={{height: "5px"}}></div>
				</div>
			);
		}
	}
	
	renderCompareToYearOneMonth(month1) {

		var compareAverageRoomsMonthArray = this.state.compareAverageRoomsMonthArray;
		var compareAverageRoomPriceMonthArray = this.state.compareAverageRoomPriceMonthArray;
		var compareTotalRevenueMonthArray = this.state.compareTotalRevenueMonthArray;

		var totalRooms = this.state.totalRooms;
		var averageRoomsMonth = compareAverageRoomsMonthArray[month1-1];
		var roomPrice = compareAverageRoomPriceMonthArray[month1-1];
		var revenue = compareTotalRevenueMonthArray[month1-1];
		var occupancyRate = Math.round(100 * averageRoomsMonth / totalRooms);
		var monthString = this.props.getMonthString(month1);
		var roomPriceString = Math.round(roomPrice);
		var revenueString = this.props.getEURString(revenue, 0);
		var showPriceYN = this.state.showPriceYN;
		
		if (revenue === 0) {
			return null;
		}

		if (showPriceYN) {
			return (
				<div key={month1} className="ReportOccupancyBoxLineContainer">
					<div className="ReportOccupancyBoxLeft">{monthString}</div>
					<div className="ReportOccupancyBoxRight">{Math.round(averageRoomsMonth)} x {roomPriceString} => <b>{revenueString}</b> - {occupancyRate}%</div>
				</div>
			);
		} else {
			return (
				<div key={month1} className="ReportOccupancyBoxLineContainer">
					<div className="ReportOccupancyBoxLeft">{monthString}</div>
					<div className="ReportOccupancyBoxRight">{Math.round(averageRoomsMonth)} - {occupancyRate}%</div>
				</div>
			);
		}
	}
	
	renderCompareToYear() {

		var compareToYear = this.state.compareToYear;
		if (compareToYear === 0) {
			return null;
		}

		var totalRooms = this.state.totalRooms;
		var compareDayCountYear = this.state.compareDayCountYear;
		var compareAverageRoomsYear = this.state.compareAverageRoomsYear;
		var compareAverageRoomPriceYear = this.state.compareAverageRoomPriceYear;
		var compareOccupancyRateYear = this.state.compareOccupancyRateYear;
		var compareTotalRevenueYear = this.state.compareTotalRevenueYear;
		var totalRevenueYearString = this.props.getEURString(compareTotalRevenueYear, 0);
		var i;
		var jsxCode;
		var jsxArray = [];
		var showPriceYN = this.state.showPriceYN;

		var trevparDay = compareTotalRevenueYear / totalRooms / compareDayCountYear;
		var trevparYear = compareTotalRevenueYear / totalRooms;
		
		var trevparString = this.props.getEURString(trevparDay, 0) + " (" + this.props.getEURString(trevparYear, 0) + ")";

		for(i=1; i<=12; i++) {
			jsxCode = this.renderCompareToYearOneMonth(i);
			jsxArray.push(jsxCode);
		}
		
		if (showPriceYN) {
			return (
				<div className="ReportOccupancyOneBox">
					<div className="ReportOccupancyBoxTitle">{this.props.getLang(ReportLang, "fullYear")} {compareToYear}</div>
					{jsxArray}
					<div className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
						<div className="ReportOccupancyBoxRight">{Math.round(compareAverageRoomsYear)} x {Math.round(compareAverageRoomPriceYear)} - {Math.round(compareOccupancyRateYear)}%</div>
					</div>
					<div className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft"><b>RRevPAR:</b></div>
						<div className="ReportOccupancyBoxRight">{trevparString}</div>
					</div>
					<div className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "total")}:</b></div>
						<div className="ReportOccupancyBoxRight">{totalRevenueYearString}</div>
					</div>				
					<div style={{height: "5px"}}></div>
				</div>		
			);
		} else {
			return (
				<div className="ReportOccupancyOneBox">
					<div className="ReportOccupancyBoxTitle">{this.props.getLang(ReportLang, "fullYear")} {compareToYear}</div>
					{jsxArray}
					<div className="ReportOccupancyBoxLineContainer">
						<div className="ReportOccupancyBoxLeft"><b>{this.props.getLang(ReportLang, "average")}:</b></div>
						<div className="ReportOccupancyBoxRight">{Math.round(compareAverageRoomsYear)} - {Math.round(compareOccupancyRateYear)}%</div>
					</div>
					<div style={{height: "5px"}}></div>
				</div>		
			);
		}
	}
	
	async onCompareToDateChanged(value) {
		this.setState({dataFileSelected: value});
		if (value === "") {
			this.setState({dataFileJsonObj: null});
			return;
		}
		var filename = value + ".csv";
		var dataFileJsonObj = await this.serverDataConvertCsvToJson(filename);
		this.setState({dataFileJsonObj: dataFileJsonObj});
	}

	onClosestDayClicked() {

		var dataFileListObj = this.state.dataFileListObj;
		if (dataFileListObj.length === 0) {
			return null;
		}

		var today = new Date();
		var year1 = today.getFullYear();
		var month1 = today.getMonth() + 1;
		var day1 = today.getDate();
		var year2;
		var month2;
		var day2;

		// last year, same month and day
		year1 = year1 - 1;
		var unixTimeSec1 = Math.floor(new Date(year1, month1 - 1, day1).getTime() / 1000);
		var unixTimeSec2;

		var i;
		var itemText1;
		var itemText2;
		var str1;
		var closestItemText2 = "";
		var diffSec;
		var diffSecMin = 1000000000;

		for(i=0; i<dataFileListObj.length; i++) {

			itemText1 = dataFileListObj[i];

			str1 = itemText1.substring(itemText1.length - 4, itemText1.length);
			if (str1 === ".csv") {
				itemText2 = itemText1.substring(0, itemText1.length - 4);
				if (itemText2.length === 10) {
					year2 = itemText2.substring(0,4);
					month2 = itemText2.substring(5,7);
					day2 = itemText2.substring(8,10);
					year2 = parseInt(year2);
					unixTimeSec2 = Math.floor(new Date(year2, month2 - 1, day2).getTime() / 1000);
					diffSec = Math.abs(unixTimeSec1 - unixTimeSec2);
					if (diffSec < diffSecMin) {
						diffSecMin = diffSec;
						closestItemText2 = itemText2;
					}
				}
			}
		}

		if (closestItemText2 === "") {
			return;
		}

		this.onCompareToDateChanged(closestItemText2);
	}

	onLastDayClicked() {
		var year2 = this.state.currentYear - 1;
		var dataFileSelected = year2 + "-12-31";
		this.onCompareToDateChanged(dataFileSelected);
	}

	onClearDayClicked() {
		this.onCompareToDateChanged("");
	}

	renderCompareToDateDropdown() {
		
		var showPriceYN = this.state.showPriceYN;
		if (!showPriceYN) {
			return null;
		}
		
		var dataFileListObj = this.state.dataFileListObj;
		if (dataFileListObj === null) {
			return null;
		}

		if (dataFileListObj.length === 0) {
			return null;
		}

		var dataFileSelected = this.state.dataFileSelected;

		var i;
		var key;
		var itemText1;
		var str1;
		var itemText2;
		var dashboardID2;
		var showYN;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<dataFileListObj.length; i++) {

			itemText1 = dataFileListObj[i];
			str1 = itemText1.substring(itemText1.length - 4, itemText1.length);
			showYN = false;
			if (str1 === ".csv") {
				showYN = true;
			}
			itemText2 = itemText1.substring(0, itemText1.length - 4);
			if (showYN) {
				if (itemText2 === "current") {
					showYN = false;
				}
			}
			
			if (showYN) {
				key = i + "-csv";			
				jsxCode = (<option key={key} value={itemText2}>{itemText2}</option>);
				jsxArray.push(jsxCode);
			}
		}

		var jsxCodeEmpty = (<option value=""></option>);
		
		var classNameClearDay = "ReportOccupancyClosestLastDay";
		if (dataFileSelected === "") {
			classNameClearDay = "ReportOccupancyClosestLastDayDisabled";
		}

		return (
			<div>
				<div style={{height: "20px"}}></div>
				<select 
					id="dropdown" 
					key="CompareToDateFilename"
					value={dataFileSelected}
					onChange={(event) => this.onCompareToDateChanged(event.target.value)}
					style={{ padding: '8px', width: '200px' }}
				>
					{jsxCodeEmpty}
					{jsxArray}
				</select>
				<div className="ReportOccupancyClosestLastContainer">
					<div className="ReportOccupancyClosestLastDay" onClick={() => this.onClosestDayClicked()}>{this.props.getLang(ReportLang, "closestDay")}</div> 
					<div className="ReportOccupancyClosestLastSeparator">-</div>
					<div className="ReportOccupancyClosestLastDay" onClick={() => this.onLastDayClicked()}>{this.props.getLang(ReportLang, "lastDay")}</div> 
					<div className="ReportOccupancyClosestLastSeparator">-</div>
					<div className={classNameClearDay} onClick={() => this.onClearDayClicked()}>{this.props.getLang(ReportLang, "clearDay")}</div>
				</div>
			</div>
		);
	}

	renderOccupancy() {

		return (
			<div>
				<div style={{height: "20px"}}></div>
				{this.renderHeader()}
				<div style={{height: "20px"}}></div>
				<center>
				{this.renderYearSummary()}
				{this.renderCompareToDateDropdown()}
				{this.renderHeaderAllYears()}
				{this.renderCompareToYear()}
				</center>
				{this.renderAllMonths()}
			</div>
		);
	}
	
	renderGraph() {

		var calcHelpersObj = this.props.calcHelpersObj;
		var dashboardID = this.props.dashboardID;
		var totalRooms = calcHelpersObj.getTotalRooms(dashboardID);

		return (
			<div>
				<div style={{height: "10px"}}></div>
				<center>
				{this.renderCompareToDateDropdown()}
				</center>
				<div style={{height: "20px"}}></div>
				<ReportOccupancyGraph
					tab={this.props.tab}
					totalRooms={totalRooms}
					currentYear={this.state.currentYear}
					currentMonth={this.state.currentMonth}
					dataFileJsonObj={this.state.dataFileJsonObj}
					averageRoomsMonthArray={this.state.averageRoomsMonthArray}
					averageRoomPriceMonthArray={this.state.averageRoomPriceMonthArray}
					averageReportRevenueMonthArray={this.state.averageReportRevenueMonthArray}
					totalRevenueMonthArray={this.state.totalRevenueMonthArray}
					averageRoomPriceYear={this.state.averageRoomPriceYear}
					averageRoomsYear={this.state.averageRoomsYear}
					occupancyRateYear={this.state.occupancyRateYear}
					averageRoomsMonthArray2={this.state.averageRoomsMonthArray2}
					averageRoomPriceMonthArray2={this.state.averageRoomPriceMonthArray2}
					averageReportRevenueMonthArray2={this.state.averageReportRevenueMonthArray2}
					totalRevenueMonthArray2={this.state.totalRevenueMonthArray2}
					averageRoomPriceYear2={this.state.averageRoomPriceYear2}
					averageRoomsYear2={this.state.averageRoomsYear2}
					occupancyRateYear2={this.state.occupancyRateYear2}
					calcHelpersObj={this.props.calcHelpersObj}
					getMonthStringShort={this.props.getMonthStringShort}
					getEURString={this.props.getEURString}
					getLang={this.props.getLang}
				/>
			</div>
		);
	}

	render() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return;			
		}
		
		var tab = this.props.tab;
		
		if (tab === "occupancy") {
			return this.renderOccupancy();
		}
		
		return this.renderGraph(tab);
	}
}

export default ReportOccupancy;

