import React  from 'react';
import './App.css';
import './Report.css';
import ReportLang from './ReportLang.json';
import ReactTooltip from 'react-tooltip';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ReportOccupancyGraphCustomTooltip from './ReportOccupancyGraphCustomTooltip.js';

class ReportOccupancyGraph extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			innerWidth: 0,
			innerHeight: 0,
			colorArray: ["grey", "#82ca9d", "orange", "blue", "green", "red", "grey", "#ca829d", "#82ca9d", "#8884d8"],
		};

		this.updateDimensions = this.updateDimensions.bind(this);
	}

	componentDidMount() {

		this.updateDimensions();
		
		window.addEventListener('resize', this.updateDimensions);
	}
	
	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
	};
	
	renderOneBarTag(yearIndex, year) {

		var colorArray = this.state.colorArray;
		var color = colorArray[yearIndex];

		var yearString = year.toString();

		return (<Bar key={yearIndex} dataKey={yearString} fill={color} />);
	}
	
	renderBarChart(tab, array1, array2) {
		
		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var jsxCode;
		var jsxArray = [];
		
		var variableType = "int";
		var variableName = tab;
		var currencyYN = calcHelpersObj.isVariableCurrencyYN(variableName);
		
		if (tab === "rooms") {
			variableType = "float";
		}

		var dateString1 = this.props.getLang(ReportLang, "today");
		var dateString2 = this.props.getLang(ReportLang, "selectedDate");
		
		if (array2 !== null) {
			jsxCode = this.renderOneBarTag(1, dateString2);
			jsxArray.push(jsxCode);
		}
		
		jsxCode = this.renderOneBarTag(0, dateString1);
		jsxArray.push(jsxCode);
		
		var i;
		var itemObj;
		var monthString;
		var data = [];

		for(i=0; i<12; i++) {

			itemObj = {};
			monthString = this.props.getMonthStringShort(i+1);
			monthString = monthString.toUpperCase();
			itemObj.name = monthString;

			if (array2 !== null) {
				itemObj[dateString2] = array2[i];
			}

			itemObj[dateString1] = array1[i];

			data.push(itemObj);
		}

		return (
			<ResponsiveContainer width="100%" height={400}>
				<BarChart
					width={500}
					height={400}
					data={data}
					margin={{
						top: 0,
						right: 20,
						left: 20,
						bottom: 5,
				}}
				>
					<Tooltip content={
						<ReportOccupancyGraphCustomTooltip
							variableType={variableType}
							currencyYN={currencyYN}
							tab={this.props.tab}
							totalRooms={this.props.totalRooms}
							getLang={this.props.getLang}
							getEURString={this.props.getEURString}								
						/>}
						cursor={false} 
					/>
					<XAxis dataKey="name" />
					<YAxis />
					<Legend />
					{jsxArray}
				</BarChart>
			</ResponsiveContainer>
	  );
	}
	
	renderFooter(tab, array1, array2) {

		var calcHelpersObj = this.props.calcHelpersObj;
		var i;
		var totalValue;
		var rows = [];
		var value0 = 0;
		var value1 = 0;
		var valueString0 = "";
		var valueString1 = "";
		var variableName = tab;
		var decimalPlaces = 0;
		var jsxCode2;
		var diffAmount;
		var percentage;
		var percentageClass;
		var percentageString;

		if (variableName === "roomrevenue") {
			value1 = 0;
			for(i=0; i<12; i++) {
				value1 += array1[i];
			}
			valueString1 = this.props.getEURString(value1, decimalPlaces);

			if (array2 !== null) {
				value0 = 0;
				for(i=0; i<12; i++) {
					value0 += array2[i];
				}
				valueString0 = this.props.getEURString(value0, decimalPlaces);
			}
		}

		if (variableName === "rooms") {
			value1 = this.props.averageRoomsYear;
			valueString1 = value1.toFixed(2) + " (" + Math.round(this.props.occupancyRateYear) + "%)";
			if (array2 !== null) {
				value0 = this.props.averageRoomsYear2;
				valueString0 = value0.toFixed(2) + " (" + Math.round(this.props.occupancyRateYear2) + "%)";
			}
		}
		
		if (variableName === "roomprice") {
			value1 = this.props.averageRoomPriceYear;
			valueString1 = this.props.getEURString(value1, decimalPlaces);
			if (array2 !== null) {
				value0 = this.props.averageRoomPriceYear2;
				valueString0 = this.props.getEURString(value0, decimalPlaces);
			}
		}

		var colorArray = this.state.colorArray;

		var color = colorArray[1];
		var style = {color: color};
		
		var titleString = this.props.getLang(ReportLang, "selectedDate");

		var key1 = "summary-1a";
		var key2 = "summary-1b";
		
		if (array2 !== null) {
			rows.push(<div key={key1} className="ReportOccupancyGraphFooterItem1" style={style}>{titleString}</div>);
			rows.push(<div key={key2} className="ReportOccupancyGraphFooterItem2" style={style}>{valueString0}</div>);
		}

		color = colorArray[0];
		style = {color: color};
		
		titleString = this.props.getLang(ReportLang, "today");

		key1 = "summary-0a";
		key2 = "summary-0b";

		rows.push(<div key={key1} className="ReportOccupancyGraphFooterItem1" style={style}>{titleString}</div>);
		rows.push(<div key={key2} className="ReportOccupancyGraphFooterItem2" style={style}>{valueString1}</div>);
		
		jsxCode2 = null;
		if (array2 !== null) {
			diffAmount = value1 - value0;
			percentage = 0;
			if (value0 !== 0) {
				percentage = 100 * diffAmount / value0;
			}
			//percentage = Math.round(percentage * 100) / 100;
			percentage = Math.round(percentage);
			percentageString = percentage;
			percentageClass = "ReportOccupancyGraphPercentageGrey";
			if (percentage > 0) {
				percentageClass = "ReportOccupancyGraphPercentageGreen";
				percentageString = "+" + percentage;
			}
			if (percentage < 0) {
				percentageClass = "ReportOccupancyGraphPercentageRed";
				percentageString = percentage;
			}
			jsxCode2 = <div class={percentageClass}>{percentageString}%</div>
		}

		return (
			<div className="ReportOccupancyGraphFooterContainer">
				<div className="ReportOccupancyGraphFooterDiv">
					<div className="ReportOccupancyGraphFooterTitle1"></div>
					<div className="ReportOccupancyGraphFooterTitle2">Total</div>
					{rows}
				</div>
				<div>
					<div>{jsxCode2}</div>
				</div>
			</div>
		);
	}
	
	render() {
		
		var tab = this.props.tab;
		var currentYear = this.props.currentYear;
		var currentMonth = this.props.currentMonth;
		var dataFileJsonObj = this.props.dataFileJsonObj;
		
		var array1 = null;
		var array2 = null;

		if (tab === "roomrevenue") {
			array1 = this.props.totalRevenueMonthArray;
			if (dataFileJsonObj !== null) {
				array2 = this.props.totalRevenueMonthArray2;
			}
		}
		
		if (tab === "rooms") {
			array1 = this.props.averageRoomsMonthArray;
			if (dataFileJsonObj !== null) {
				array2 = this.props.averageRoomsMonthArray2;
			}
		}
		
		if (tab === "roomprice") {
			array1 = this.props.averageRoomPriceMonthArray;
			if (dataFileJsonObj !== null) {
				array2 = this.props.averageRoomPriceMonthArray2;
			}
		}
		
		return (
			<div>
				{this.renderBarChart(tab, array1, array2)}
				<div style={{height: "20px"}}></div>
				{this.renderFooter(tab, array1, array2)}
				<div style={{height: "20px"}}></div>
			</div>
		);
	}
}

export default ReportOccupancyGraph;

