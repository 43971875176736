import React  from 'react';
import './App.css';
import './Report.css';
import DashboardLang from './DashboardLang.json';

class ReportOccupancyGraphCustomTooltip extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);		
	}

	getValueFromItemObj(itemObj) {
		var name = itemObj.dataKey;
		var payload = itemObj.payload;
		var value = payload[name];
		return value;
	}

	renderOneLine(index, itemObj) {

		var tab = this.props.tab;
		var currencyYN = this.props.currencyYN;
		var variableType = this.props.variableType;
		var totalRooms = this.props.totalRooms;
		var name = itemObj.dataKey;
		var color = itemObj.color;
		var value = this.getValueFromItemObj(itemObj);
		var percentage = 0;
		
		const divStyle = { color: color };

		var decimalPlaces = 0;
		if (variableType === "float") {
			decimalPlaces = 2;
		}
		
		var valueString = value;
		if (currencyYN) {
			valueString = this.props.getEURString(value, decimalPlaces);
		} else {
			if (decimalPlaces === 2) {
				valueString = value.toFixed(2);
			}
		}
		
		if (totalRooms === 0) {
			totalRooms = 1;
		}
		
		if (tab === "rooms") {
			percentage = 100*value/totalRooms;
			percentage = Math.round(percentage);
			valueString = Math.round(value) + " (" + percentage + "%)";
		}

		return (
			<div key={index} style={divStyle}>{name}: {valueString}</div>
		);
	}
	
	render() {
		
		var active = this.props.active;
		var payload = this.props.payload;
		
		if (!active) {
			return null;
		}

		if (payload === null) {
			return null;
		}
		
		var itemObj = payload[0];
		var onePayload = itemObj.payload;
		var name = onePayload.name;
		var monthString = name;
		var i;
		var jsxCode1;
		var jsxCode2;
		var jsxArray = [];
		var value0;
		var value1;
		var diffAmount;
		var percentage;
		var percentageClass;
		var percentageString;
		
		for(i=0; i<payload.length; i++) {
			itemObj = payload[i];
			jsxCode1 = this.renderOneLine(i, itemObj);
			jsxArray.push(jsxCode1);
		}
		
		jsxCode2 = null;
		if (payload.length === 2) {
			itemObj = payload[0];
			value0 = this.getValueFromItemObj(itemObj);
			itemObj = payload[1];
			value1 = this.getValueFromItemObj(itemObj);
			diffAmount = value1 - value0;
			percentage = 0;
			if (value0 !== 0) {
				percentage = 100 * diffAmount / value0;
			}
			//percentage = Math.round(percentage * 100) / 100;
			percentage = Math.round(percentage);
			percentageString = percentage;
			percentageClass = "ReportOccupancyGraphPercentageGrey";
			if (percentage > 0) {
				percentageClass = "ReportOccupancyGraphPercentageGreen";
				percentageString = "+" + percentage;
			}
			if (percentage < 0) {
				percentageClass = "ReportOccupancyGraphPercentageRed";
				percentageString = percentage;
			}
			jsxCode2 = <div class={percentageClass}>{percentageString}%</div>
		}
		
		return (
			<div className="ReportOccupancyGraphCustomTooltip">
				<div>
					<div>{monthString}</div>
					<div>{jsxArray}</div>
					<div>{jsxCode2}</div>
				</div>
			</div>
		);
	}
}

export default ReportOccupancyGraphCustomTooltip;

