import React  from 'react';
import './App.css';
import './Report.css';
import ReportLang from './ReportLang.json';
import { AiOutlineFilePdf } from "react-icons/ai"
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

class ReportBillList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			index: 0,
			jsonObj: null,
			searchText: "",
			minPriceTotal: 0,
			count: 100,
			businessUnitListJsonObj: null,
			placeExpenditureListJsonObj: null,
			searchBillCount: 0,
			priceTotalERPSum: 0,
			priceTotalSum: 0,
			businessUnitID: 0,
			placeExpenditureID: "",
			showAllYN: false,
		};
	}

	componentDidMount() {

		var dashboardUserType = this.props.dashboardUserType;
		var placeExpenditureID = "";
		var allBillsYN = this.props.allBillsYN;
		
		if (!allBillsYN) {
			if (dashboardUserType === 1002) {
				placeExpenditureID = "2";
			}

			if (dashboardUserType === 1003) {
				placeExpenditureID = "5";
			}

			if (dashboardUserType === 1004) {
				placeExpenditureID = "10";
			}
		}
		
		this.setState({
			placeExpenditureID: placeExpenditureID,
		});
		
		this.serverSearchBillList(0, this.state.count, this.props.selectedYear, "", 0, this.state.businessUnitID, placeExpenditureID);

		var companyID = this.props.companyID;
		this.serverGetBusinessUnitList(companyID);
		this.serverGetPlaceExpenditureList(companyID);
	}
	
	async serverSearchBillList(index, count, year, searchText, minPriceTotal, businessUnitID, placeExpenditureID) {

		this.setState({
			jsonObj: null,
		});

		if (minPriceTotal === "") {
			minPriceTotal = 0;
		}

		this.serverSearchBillListReturnSum(year, searchText, minPriceTotal, businessUnitID, placeExpenditureID);

		var allBillsYN = this.props.allBillsYN;
		var dashboardID;
		var companyID = this.props.companyID;
		
		if (allBillsYN) {
			dashboardID = 0;
		} else {
			dashboardID = this.props.dashboardID;
		}
		
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/searchbilllist?dashboardid=" + dashboardID;
		url = url + "&companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&searchtext=" + encodeURIComponent(searchText);
		url = url + "&minpricetotal=" + minPriceTotal;
		url = url + "&businessunit=" + businessUnitID;
		url = url + "&placeofexpenditure=" + placeExpenditureID;
		url = url + "&sortby=billID&sortasc=0";
		url = url + "&index=" + index;
		url = url + "&count=" + count;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		var i;
		
		for(i=0; i<jsonObj.length; i++) {
			jsonObj[i].openYN = false;
			jsonObj[i].billItemListArray = null;
		}

		this.setState({
			jsonObj: jsonObj,
		});
	}
	
	async serverSearchBillListReturnSum(year, searchText, minPriceTotal, businessUnitID, placeExpenditureID) {

		var allBillsYN = this.props.allBillsYN;
		var dashboardID;
		var companyID = this.props.companyID;
		
		if (allBillsYN) {
			dashboardID = 0;
		} else {
			dashboardID = this.props.dashboardID;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/searchbilllistreturnsum?dashboardid=" + dashboardID;
		url = url + "&companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&searchtext=" + encodeURIComponent(searchText);
		url = url + "&minpricetotal=" + minPriceTotal;
		url = url + "&businessunit=" + businessUnitID;
		url = url + "&placeofexpenditure=" + placeExpenditureID;

		var res = await fetch(url);
		var jsonObj = await res.json();
	
		try {
			var itemObj = jsonObj[0];

			var searchBillCount = itemObj.count;
			var priceTotalERPSum = itemObj.priceTotalERPSum;
			var priceTotalSum = itemObj.priceTotalSum;
			
			this.setState({
				searchBillCount: searchBillCount,
				priceTotalERPSum: priceTotalERPSum,
				priceTotalSum: priceTotalSum,
			});
		} catch (err) {
		}
	}

	async serverGetBillItemList(billAutoID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + '/getbillitemlist?billautoid=' + billAutoID;

		var jsonObj = null;

		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {}
		
		return jsonObj;
	}

	async serverGetAllBillItemList(businessUnitID, placeExpenditureID) {

		var allBillsYN = this.props.allBillsYN;
		var dashboardID;
		var companyID = this.props.companyID;
		var year = this.props.selectedYear;
		
		if (allBillsYN) {
			dashboardID = 0;
		} else {
			dashboardID = this.props.dashboardID;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getallbillitemlist?dashboardid=" + dashboardID;
		url = url + "&companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&businessunit=" + businessUnitID;
		url = url + "&placeofexpenditure=" + placeExpenditureID;
		console.log(url);

		var jsonObj = null;
	
		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {
		}

		return jsonObj;
	}

	async serverGetBusinessUnitList(companyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/company/" + companyID + "/businessUnit.json";

		var jsonObj = null;

		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {
		}

		this.setState({businessUnitListJsonObj: jsonObj});
	}

	async serverGetPlaceExpenditureList(companyID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/company/" + companyID + "/placeOfExpenditure.json";

		var jsonObj = null;

		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {
		}
		
		this.setState({placeExpenditureListJsonObj: jsonObj});
	}

	async onItemClicked(index) {
		
		var jsonObj = this.state.jsonObj;
		var itemObj = jsonObj[index];

		var openYN = itemObj.openYN;
		openYN = !openYN;
		itemObj.openYN = openYN;

		var billAutoID = itemObj.billAutoID;
		var billItemListArray = itemObj.billItemListArray;
		
		if (billItemListArray === null) {
			billItemListArray = await this.serverGetBillItemList(billAutoID);
			itemObj.billItemListArray = billItemListArray;
		}
		
		this.setState({jsonObj: jsonObj});
	}
	
	onSearchTextChanged(value) {
		var searchText = value; //.toLowerCase();
		this.setState({
			index: 0,
			searchText: searchText,
		});
		var count = this.resetCount();
		this.serverSearchBillList(this.state.index, count, this.props.selectedYear, searchText, this.state.minPriceTotal, this.state.businessUnitID, this.state.placeExpenditureID);
	}
	
	onMinPriceTotalChanged(value) {

		var minPriceTotal = "";
		try {
			minPriceTotal = parseInt(value);
		} catch (err) {
		}
		
		if (isNaN(minPriceTotal)) {
			minPriceTotal = "";
		}
		this.setState({
			index: 0,
			minPriceTotal: minPriceTotal,
		});
		var count = this.resetCount();
		this.serverSearchBillList(this.state.index, count, this.props.selectedYear, this.state.searchText, minPriceTotal, this.state.businessUnitID, this.state.placeExpenditureID);
	}
	
	onSelectedYearChanged(value) {
		var selectedYear = parseInt(value);
		this.props.setSelectedYear(selectedYear);
		var count = this.resetCount();
		this.serverSearchBillList(this.state.index, count, selectedYear, this.state.searchText, this.state.minPriceTotal, this.state.businessUnitID, this.state.placeExpenditureID);
	}
	
	onBusinessUnitChanged(value) {
		var businessUnitID = parseInt(value);
		this.setState({
			index: 0,
			placeExpenditureID: "",
			businessUnitID: businessUnitID
		});
		var count = this.resetCount();
		this.serverSearchBillList(this.state.index, count, this.props.selectedYear, this.state.searchText, this.state.minPriceTotal, businessUnitID, this.state.placeExpenditureID);
	}

	onPlaceExpenditureChanged(value) {
		var placeExpenditureID = value;
		this.setState({
			index: 0,
			placeExpenditureID: placeExpenditureID
		});
		var count = this.resetCount();
		this.serverSearchBillList(this.state.index, count, this.props.selectedYear, this.state.searchText, this.state.minPriceTotal, this.state.businessUnitID, placeExpenditureID);
	}

	handlePrev() {
		var index = this.state.index;
		var count = this.state.count;
		index = index - count;
		this.setState({index: index});
		this.serverSearchBillList(index, count, this.props.selectedYear, this.state.searchText, this.state.minPriceTotal, this.state.businessUnitID, this.state.placeExpenditureID);
	}

	handleNext() {
		var index = this.state.index;
		var count = this.state.count;
		index = index + count;
		this.setState({index: index});
		this.serverSearchBillList(index, count, this.props.selectedYear, this.state.searchText, this.state.minPriceTotal, this.state.businessUnitID, this.state.placeExpenditureID);
	}
	
	handleShowAllBills() {
		var index = 0;
		var count = 100000000;
		this.setState({
			index: index,
			count: count,
			showAllYN: true,
		});
		this.serverSearchBillList(index, count, this.props.selectedYear, this.state.searchText, this.state.minPriceTotal, this.state.businessUnitID, this.state.placeExpenditureID);
	}
	
	resetCount() {
		var count = 100;
		this.setState({
			count: count,
			showAllYN: false,
		});
		return count;
	}

	renderYearDropdown() {
		
		var selectedYear = this.props.selectedYear;
		
		return (
			<select 
				id="searchYear" 
				key="searchYear"
				value={selectedYear}
				onChange={(event) => this.onSelectedYearChanged(event.target.value)}
				style={{ padding: '8px', width: '100px' }}
			>
				<option value="2022">2022</option>);
				<option value="2023">2023</option>);
				<option value="2024">2024</option>);
				<option value="2025">2025</option>);
			</select>
		);
	}
	
	renderBusinessUnitDropdown() {
		
		var allBillsYN = this.props.allBillsYN;
		if (!allBillsYN) {
			return null;
		}

		var jsonObj = this.state.businessUnitListJsonObj;

		if (jsonObj === null) {
			return null;
		}

		var businessUnitID = this.state.businessUnitID;

		var i;
		var key;
		var itemObj;
		var id;
		var itemText;
		var dashboardID2;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<jsonObj.length; i++) {

			itemObj = jsonObj[i];
			id = itemObj.id;
			itemText = itemObj.text;
			key = i + "-businessUnit";
			
			jsxCode = (<option key={key} value={id}>{itemText}</option>);
			jsxArray.push(jsxCode);
		}

		var jsxCodeEmpty = (<option value={0}></option>);

		return (
			<select 
				id="dropdown" 
				key="businessUnit"
				value={businessUnitID}
				onChange={(event) => this.onBusinessUnitChanged(event.target.value)}
				style={{ padding: '8px', width: '120px' }}
			>
				{jsxCodeEmpty}
				{jsxArray}
			</select>
		);
	}

	renderPlaceExpenditureDropdown() {
		
		var jsonObj = this.state.placeExpenditureListJsonObj;

		if (jsonObj === null) {
			return null;
		}

		var placeExpenditureID = this.state.placeExpenditureID;
		var dashboardID1 = this.props.dashboardID;
		var businessUnitID1 = this.state.businessUnitID;

		var dashboardAppUserType = this.props.dashboardAppUserType;
		var dashboardUserType = this.props.dashboardUserType;
		var allBillsYN = this.props.allBillsYN;

		var i;
		var key;
		var itemObj;
		var id;
		var itemText;
		var dashboardID2;
		var businessUnitID2;
		var jsxCode;
		var jsxArray = [];
		var addYN;
		var emptyYN = false;

		for(i=0; i<jsonObj.length; i++) {

			itemObj = jsonObj[i];
			id = itemObj.id;
			itemText = itemObj.text;
			dashboardID2 = itemObj.dashboardID;
			businessUnitID2 = itemObj.businessUnitID;
			key = i + "-placeOfExpenditure";
			
			addYN = false;
			
			if (dashboardID1 === dashboardID2) {

				if (dashboardAppUserType === 99) {
					addYN = true;
					emptyYN = true;
				}

				if (dashboardAppUserType >= 100) {
					addYN = true;
					emptyYN = true;
				}

				if (dashboardAppUserType === 1) {

					if (dashboardUserType < 1000) {
						addYN = true;
						emptyYN = true;
					}
					
					if (dashboardUserType === 1001) {
					}

					if (dashboardUserType === 1002) {
						if ((id === "2") || (id === "3") || (id === "9")) {
							addYN = true;
						}
					}

					if (dashboardUserType === 1003) {
						if ((id === "4") || (id === "5") || (id === "6")) {
							addYN = true;
						}
					}

					if (dashboardUserType === 1004) {
						if (id === "10") {
							addYN = true;
						}
					}
				}
			}
			
			if (allBillsYN) {
				
				if (businessUnitID1 === 0) {
					addYN = true;
				}

				if (businessUnitID1 === businessUnitID2) {
					addYN = true;
				}

				emptyYN = true;
			}

			if (addYN) {
				jsxCode = (<option key={key} value={id}>{itemText}</option>);
				jsxArray.push(jsxCode);
			}
		}

		var jsxCodeEmpty = null;
		if (emptyYN) {
			jsxCodeEmpty = (<option value=""></option>);
		}

		return (
			<select 
				id="dropdown" 
				key="placeOfExpenditure"
				value={placeExpenditureID}
				onChange={(event) => this.onPlaceExpenditureChanged(event.target.value)}
				style={{ padding: '8px', width: '150px' }}
			>
				{jsxCodeEmpty}
				{jsxArray}
			</select>
		);
	}
	
	renderSearch() {
		
		var index = this.state.index;
		var searchText = this.state.searchText;
		var minPriceTotal = this.state.minPriceTotal;
		var searchBillCount = this.state.searchBillCount;
		var priceTotalERPSum = this.state.priceTotalERPSum;
		
		var totalString = this.props.getEURString(priceTotalERPSum, 2);
				
		return (
			<div className="main-container">
				{this.renderYearDropdown()}
				<input
					type="text"
					placeholder="Search..."
					value={searchText}
					onChange={(event) => this.onSearchTextChanged(event.target.value)}
					style={{ padding: '8px', width: '200px' }}
				/>
				<input
					type="number"
					placeholder="Min Total Price..."
					value={minPriceTotal}
					onChange={(event) => this.onMinPriceTotalChanged(event.target.value)}
					style={{ padding: '8px', width: '120px' }}
				/>
				{this.renderBusinessUnitDropdown()}				
				{this.renderPlaceExpenditureDropdown()}
				<div>{searchBillCount} bills, {totalString}</div>
			</div>
		)
	}
	
	renderPrevNext() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return null;
		}

		var showAllYN = this.state.showAllYN;
		if (showAllYN) {
			return null;
		}

		var billCount = jsonObj.length;
		var index = this.state.index;
		var count = this.state.count;

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (billCount === count) {
			nextYN = true;
		}

		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
				<div 
					className="ListPrevNextLink"
				>
				</div>
				<div 
					className="ListPrevNextLink"
					onClick={() => this.handleShowAllBills()}
				>
					Show All Bills
				</div>
			</div>
		)
	}
	
	getBusinessUnitText(businessUnit1) {

		businessUnit1 = parseInt(businessUnit1);

		var jsonObj = this.state.businessUnitListJsonObj;
		if (jsonObj === null) {
			return "";
		}

		if (businessUnit1 === 0) {
			return "";
		}

		var i;
		var itemObj;
		var businessUnit2;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			businessUnit2 = itemObj.id;
			if (businessUnit1 === businessUnit2) {
				return itemObj.text;
			}
		}

		return "";
	}

	getPlaceOfExpenditureText(placeOfExpenditure1) {
		
		var jsonObj = this.state.placeExpenditureListJsonObj;

		if (jsonObj === null) {
			return "";
		}
		
		if (placeOfExpenditure1 === "") {
			return "";
		}

		var i;
		var itemObj;
		var placeOfExpenditure2;

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			placeOfExpenditure2 = itemObj.id;
			if (placeOfExpenditure1 === placeOfExpenditure2) {
				return itemObj.text;
			}
		}

		return "";
	}

	renderOneProduct(productItemObj) {
		
		var billItemID = productItemObj.billItemID;
		var key = billItemID + "-product";
		var productName = productItemObj.productName;
		//var productNameEN = productItemObj.productNameEN;
		var quantity = productItemObj.quantity;
		var priceUnit = productItemObj.priceUnit;
		var vatPercentage = productItemObj.vatPercentage;
		var vatAmount = productItemObj.vatAmount;
		var priceTotal = productItemObj.priceTotal;
		var weightGrams = productItemObj.weightGrams;
		var piecesInPackage = productItemObj.piecesInPackage;
		var productCategory = productItemObj.productCategory;
		var productSubcategory = productItemObj.productSubcategory;
		var productShortName = productItemObj.productShortName;

		var detailsString = "";
		if ((weightGrams !== null) && (weightGrams !== 0)) {
			detailsString = weightGrams + "g";
		}
		if ((piecesInPackage !== null) && (piecesInPackage !== 0) && (piecesInPackage !== 1)) {
			if (detailsString === "") {
				detailsString = piecesInPackage + " " + this.props.getLang(ReportLang, "pieces");				
			} else {
				detailsString = detailsString + ", " + piecesInPackage + " " + this.props.getLang(ReportLang, "pieces");
			}
		}
		
		var categoryString = productCategory + " / " + productSubcategory + " / " + productShortName;

		return (
			<tr key={key}>
				<td className="ReportExpensesProductNameItem">{productName}</td>
				<td className="ReportExpensesProductQuantityItem">{quantity}</td>
				<td className="ReportExpensesProductPPUnitItem">{this.props.getEURString(priceUnit, 2)}</td>
				<td className="ReportExpensesProductVATItem">{vatAmount} / {vatPercentage}%</td>
				<td className="ReportExpensesProductPTotalItem">{this.props.getEURString(priceTotal, 2)}</td>
				<td className="ReportExpensesProductDetailsItem">{detailsString}</td>
				<td className="ReportExpensesProductCategoryItem">{categoryString}</td>
			</tr>
		);
	}
	
	renderProducts(index, productItemArray) {

		if (productItemArray === null) {
			return null;
		}
		
		var i;
		var key = index + "-productlist";
		var productItemObj;
		var jsxCode;
		var jsxArray = [];
		var length = productItemArray.length;
		
		if (length === 0) {
			return null;
		}

		for(i=0; i<length; i++) {
			productItemObj = productItemArray[i];
			jsxCode = this.renderOneProduct(productItemObj);
			jsxArray.push(jsxCode);
		}

		return (
			<div className="products-section" key={key}>
				<table className="product-table">
					<thead>
						<tr>
							<th className="ReportExpensesProductNameHeader">{this.props.getLang(ReportLang, "productName")}</th>
							<th className="ReportExpensesProductQuantityHeader">{this.props.getLang(ReportLang, "quantity")}</th>
							<th className="ReportExpensesProductPPUnitHeader">{this.props.getLang(ReportLang, "pricePerUnit")}</th>
							<th className="ReportExpensesProductVATHeader">{this.props.getLang(ReportLang, "vat")}</th>
							<th className="ReportExpensesProductPTotalHeader">{this.props.getLang(ReportLang, "totalPrice")}</th>
							<th className="ReportExpensesProductDetailsHeader">{this.props.getLang(ReportLang, "packaging")}</th>
							<th className="ReportExpensesProductCategoryHeader">{this.props.getLang(ReportLang, "taxonomy")}</th>
						</tr>
					</thead>
					<tbody>
						{jsxArray}
					</tbody>
				</table>
			</div>
		);
	}
  
	renderOneBillOpen(index, itemObj) {

		var billID = itemObj.billID;
		var key = billID + "-open";
		var sellerName = itemObj.sellerName;
		var priceTotal = itemObj.priceTotal;
		var vatTotal = itemObj.vatTotal;
		var discount = itemObj.discount;
		var sellerNameERP = itemObj.sellerNameERP;
		var priceTotalERP = itemObj.priceTotalERP;
		var googleStorageID = itemObj.googleStorageID;
		var userFullName = itemObj.userFullName;
		var companyID = itemObj.companyID;
		var year = itemObj.year;
		var konto = itemObj.konto;
		var kontoTitle = itemObj.kontoTitle;
		var businessUnit = itemObj.businessUnit;
		var placeOfExpenditure = itemObj.placeOfExpenditure;
		var paymentReferenceNumber = itemObj.paymentReferenceNumber;
		var pdfYN = itemObj.pdfYN;

		var businessUnitText = this.getBusinessUnitText(businessUnit);
		var placeOfExpenditureText = this.getPlaceOfExpenditureText(placeOfExpenditure);

		var billItemListArray = itemObj.billItemListArray;
		
		return (
			<tr key={key}>
				<td colSpan="10">
				
				<div className="ReportExpensesOpenBillContainer">
				<div className="ReportExpensesOpenBill">

					<strong>{this.props.getLang(ReportLang, "ulazniRacunBroj")}:</strong> {billID}<br/>

					<strong>{this.props.getLang(ReportLang, "sellerName")}:</strong> {sellerNameERP} / {sellerName}<br/>

					<strong>{this.props.getLang(ReportLang, "totalPrice")}:</strong>{' '}
					{this.props.getEURString(priceTotalERP, 2)} / {this.props.getEURString(priceTotal, 2)}<br/>

					<strong>{this.props.getLang(ReportLang, "discount")}:</strong>{' '}
					{this.props.getEURString(discount, 2)}<br/>

					<strong>{this.props.getLang(ReportLang, "vatTotal")}:</strong>{' '}
					{this.props.getEURString(vatTotal, 2)}<br/>

					<strong>Konto:</strong>{' '}
					{konto}, {kontoTitle}<br/>

					<strong>{this.props.getLang(ReportLang, "businessUnit")}:</strong>{' '}
					{businessUnit}, {businessUnitText}<br/>

					<strong>{this.props.getLang(ReportLang, "placeOfExpenditure")}:</strong>{' '}
					{placeOfExpenditure}, {placeOfExpenditureText}<br/>

					<strong>{this.props.getLang(ReportLang, "paymentReferenceNumber")}:</strong>{' '}
					{paymentReferenceNumber}<br/>

					<strong>{this.props.getLang(ReportLang, "user")}:</strong> {userFullName}<br/>
					
					{this.renderProducts(index, billItemListArray)}
				</div>
				</div>

				</td>
			</tr>
		);
	}
	
	getPdfURL(googleStorageID, year) {

		if (googleStorageID === "") {
			return null;
		}

		var companyID = this.props.companyID;

		var bucketName = 'bill-' + companyID + '-' + year;

		var pdfURL =
		  'https://storage.googleapis.com/' +
		  bucketName +
		  '/' +
		  googleStorageID +
		  '.pdf';

		return pdfURL;
	}

	renderOneBill(index, itemObj) {

		var billAutoID = itemObj.billAutoID;
		var billID = itemObj.billID;
		var konto = itemObj.konto;
		var kontoTitle = itemObj.kontoTitle;
		var sellerNameERP = itemObj.sellerNameERP;
		var sellerName = itemObj.sellerName;
		var priceTotalERP = itemObj.priceTotalERP;
		var priceTotal = itemObj.priceTotal;
		var year = itemObj.year;
		var monthERP = itemObj.monthERP;
		var dayERP = itemObj.dayERP;
		var key = index + "-tr";
		
		var issueDateYear = itemObj.issueDateYear;
		var issueDateMonth = itemObj.issueDateMonth;
		var issueDateDay = itemObj.issueDateDay;
		var dateString = "";
		var dateString1 = "";
		var dateString2 = "";
		
		dateString1 = dayERP + "." + monthERP + "." + year;
		dateString = dateString1;
		if (issueDateYear > 0) {
			dateString2 = issueDateDay + "." + issueDateMonth + "." + issueDateYear;
			dateString = dateString1 + " / " + dateString2;
		}

		var pdfYN = itemObj.pdfYN;
		var googleStorageID = itemObj.googleStorageID;
		var pdfURL = "";
		var pdfJsxCode = null;
		if (pdfYN) {
			pdfURL = this.getPdfURL(googleStorageID, year);
			pdfJsxCode = (
				<a href={pdfURL} target="_blank" rel="noopener noreferrer">
					<AiOutlineFilePdf style={{ color: "black", width: "20px", height: "20px" }} />
				</a>
			);
		}
		
		var placeOfExpenditure = itemObj.placeOfExpenditure;
		var placeOfExpenditureString = this.getPlaceOfExpenditureText(placeOfExpenditure);

		var trClassName = "ReportExpensesBillClosed";
		var openYN = itemObj.openYN;
		
		if (openYN) {
			trClassName = "ReportExpensesBillOpen";
		}
		
		var jsxCode = (		
			<tr key={key} className={trClassName} onClick={() => this.onItemClicked(index)} >
				<td className="ReportExpensesPDFItem">{pdfJsxCode}</td>
				<td>{billID}</td>
				<td>{dateString}</td>
				<td>{konto} - {kontoTitle}</td>
				<td>{placeOfExpenditureString}</td>
				<td>{sellerNameERP} / {sellerName}</td>
				<td>{this.props.getEURString(priceTotalERP, 2)} / {this.props.getEURString(priceTotal, 2)}</td>
			</tr>
		);
		
		return [priceTotalERP, jsxCode];
	}

	renderSelectedBills() {
		
		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return null;
		}
		
		var i;
		var length = jsonObj.length;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		var openYN;
		var priceTotalERP;
		var total = 0;
		
		// temp
		//length = 1;
		
		for(i=0; i<length; i++) {
			itemObj = jsonObj[i];
			[priceTotalERP, jsxCode] = this.renderOneBill(i, itemObj);
			if (jsxCode !== null) {
				total = total + priceTotalERP;
				jsxArray.push(jsxCode);
				openYN = itemObj.openYN;
				if (openYN) {
					jsxCode = this.renderOneBillOpen(i, itemObj);
					jsxArray.push(jsxCode);
				}
			}
		}
		
		return (
			<div>
				<table>
					<tbody>
						<tr key="header">
							<td className="ReportExpensesPDFHeader"></td>
							<td className="ReportExpensesUlazniRacunBrojHeader">{this.props.getLang(ReportLang, "ulazniRacunBroj")}</td>
							<td className="ReportExpensesDatumHeader">{this.props.getLang(ReportLang, "datum")}</td>
							<td className="ReportExpensesKontoHeader">{this.props.getLang(ReportLang, "konto")}</td>
							<td className="ReportExpensesMjTroskaHeader">{this.props.getLang(ReportLang, "placeExpenditure")}</td>
							<td className="ReportExpensesPoslovniPartnerHeader">{this.props.getLang(ReportLang, "poslovniPartner")}</td>
							<td className="ReportExpensesPotrazujeHeader">{this.props.getLang(ReportLang, "potrazuje")}</td>
						</tr>
						{jsxArray}
					</tbody>
				</table>
			</div>
		);
	}

	calcBillSummary() {
		
		var sumArray = [0,0,0,0,0,0,0,0,0,0,0,0,0];

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return sumArray;
		}

		var i;
		var itemObj;
		var length = jsonObj.length;
		var priceTotalERP;
		var monthERP;

		for(i=0; i<length; i++) {
			itemObj = jsonObj[i];
			priceTotalERP = itemObj.priceTotalERP;
			monthERP = itemObj.monthERP;
			if ((monthERP < 1) || (monthERP > 12)) {
				sumArray[0] += priceTotalERP;
			} else {
				sumArray[monthERP] += priceTotalERP;
			}
		}
		
		return sumArray;
	}

	renderOneBillMonthly(index, sumValue) {
		
		var monthString = "";
		var key = index + "-monthly";
		var sumValueString = this.props.getEURString(sumValue, 2);
		var selectedYear = this.props.selectedYear;
		
		if (index === 0) {
			monthString = "Missing month";
			if (sumValue === 0) {
				return null;
			}
		} else {
			var month = this.props.getTwoDigits(index);
			monthString = month + "/" + selectedYear;
		}
		
		return (
			<tr key={key}>
				<td className="ReportExpensesMonthlyMonthItem">{monthString}</td>
				<td className="ReportExpensesMonthlySumItem">{sumValueString}</td>
			</tr>
		);
	}

	renderBillsMonthly() {

		var showAllYN = this.state.showAllYN;
		if (!showAllYN) {
			return null;
		}
		
		var sumArray = this.calcBillSummary();
		
		var i;
		var sumValue;
		var jsxCode;
		var jsxArray = [];
		var totalSum = 0;
		
		for(i=0; i<sumArray.length; i++) {
			sumValue = sumArray[i];
			totalSum += sumValue;
			jsxCode = this.renderOneBillMonthly(i, sumValue);
			jsxArray.push(jsxCode);
		}
		
		var totalSumString = this.props.getEURString(totalSum, 2);
		
		return (
			<div>
				<hr/>
				<div>
					<div style={{height: "5px"}}></div>
					<table className="ReportExpensesMonthlyTable">
						<tr>
							<td className="ReportExpensesMonthlyMonthHeader">{this.props.getLang(ReportLang, "month")}</td>
							<td className="ReportExpensesMonthlySumHeader">{this.props.getLang(ReportLang, "total")}</td>
						</tr>
						{jsxArray}
						<tr>
							<td></td>
							<td className="ReportExpensesMonthlySumItem"><b>{totalSumString}</b></td>
						</tr>
					</table>
				</div>
			</div>
		);
	}
	
	getTodayDateString() {
		
		var today = new Date();
		var year = today.getFullYear();
		var month = today.getMonth() + 1;
		var day = today.getDate();		
		var dateString = year + "-" + this.props.getTwoDigits(month) + "-" + this.props.getTwoDigits(day);
		
		return dateString;
	}
	
	convertFloatToValueWithColon(floatNumber) {
		if (floatNumber === null) {
			return "0,00";
		}
		var string1 = floatNumber.toString();
		var string2 = string1.replace(".", ",");
		return string2;
	}

	downloadProductsConvertItem(itemObj) {

		var itemObj2 = {};

		var year = this.props.selectedYear;
		var monthERP = itemObj.monthERP;
		var dayERP = itemObj.dayERP;
		var dateString = dayERP + "." + monthERP + "." + year;

		// ako je na HRV onda ,
		// ako je na ENG onda .
		
		var language = this.props.language;		
		var priceUnit = itemObj.priceUnit;
		var priceTotal = itemObj.priceTotal;
		
		if (language === "hr") {
			priceUnit = this.convertFloatToValueWithColon(priceUnit);
			priceTotal = this.convertFloatToValueWithColon(priceTotal);
		}

		itemObj2.date = dateString;
		itemObj2.productName = itemObj.productName;
		itemObj2.productNameEN = itemObj.productNameEN;
		itemObj2.priceUnit = priceUnit;
		itemObj2.quantity = itemObj.quantity;
		itemObj2.vatPercentage = itemObj.vatPercentage;
		itemObj2.vatAmount = itemObj.vatAmount;
		itemObj2.priceTotal = priceTotal;
		itemObj2.weightGrams = itemObj.weightGrams;
		itemObj2.piecesInPackage = itemObj.piecesInPackage;
		itemObj2.productCategory = itemObj.productCategory;
		itemObj2.productSubcategory = itemObj.productSubcategory;
		itemObj2.productShortName = itemObj.productShortName;
		itemObj2.billID = itemObj.billID;
		itemObj2.sellerNameERP = itemObj.sellerNameERP;

		return itemObj2;
	}

	async handleDownloadProducts() {
		
		console.log("handleDownloadProducts1");
		
		var itemArray1 = await this.serverGetAllBillItemList(this.state.businessUnitID, this.state.placeExpenditureID);
		if (itemArray1 === null) {
			this.props.showToast("No products found");
			return;
		}

		var i;
		var itemObj1;
		var itemObj2;
		var itemArray2 = [];
		var language = this.props.language;
		var length = itemArray1.length;

		if (length === 0) {
			this.props.showToast("No products found");
			return;
		}
		
		for(i=0; i<length; i++) {
			itemObj1 = itemArray1[i];
			itemObj2 = this.downloadProductsConvertItem(itemObj1);
			itemArray2.push(itemObj2);
		}

		var cellRef;
		var cellObj;
		var workbook = new ExcelJS.Workbook();

		var worksheet1 = workbook.addWorksheet("Products");
		var columns = Object.keys(itemArray2[0]).map((key) => ({ header: key, key: key }));	
		worksheet1.columns = columns;
		worksheet1.columns = [
			{ key: 'A', width: 10 },
			{ key: 'B', width: 40 },
			{ key: 'C', width: 3 },
			{ key: 'D', width: 10 },
			{ key: 'E', width: 10 },
			{ key: 'F', width: 10 },
			{ key: 'G', width: 15 },
			{ key: 'H', width: 15 },
			{ key: 'I', width: 15 },
			{ key: 'J', width: 15 },
			{ key: 'K', width: 15 },
			{ key: 'L', width: 10 },
			{ key: 'M', width: 30 },
		];
		itemArray2.forEach((itemObj) => {
			worksheet1.addRow(itemObj);
		});
	
		cellObj = worksheet1.getCell("A1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("B1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("C1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("D1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("E1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("F1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("G1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("H1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("I1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("J1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("K1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("L1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("M1");
		cellObj.font = { bold: true };

		for(i=2; i<=itemArray2.length+1; i++) {
			cellRef = "D" + i;
			cellObj = worksheet1.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		for(i=2; i<=itemArray2.length+1; i++) {
			cellRef = "F" + i;
			cellObj = worksheet1.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		var dateString = this.getTodayDateString();

		var businessUnitID = this.state.businessUnitID;
		var placeExpenditureID = this.state.placeExpenditureID;
		
		var businessUnitText = "";
		if (businessUnitID !== 0) {
			businessUnitText = this.getBusinessUnitText(businessUnitID);
		}
		var placeOfExpenditureText = "";
		if (placeExpenditureID !== "") {
			placeOfExpenditureText = this.getPlaceOfExpenditureText(placeExpenditureID);
		}
		
		var allBillsYN = this.props.allBillsYN;
		var dashboardName = this.props.dashboardName;
		
		var filename = dateString;
		if (!allBillsYN) {
			filename = filename + " " + dashboardName;
		}
		if (businessUnitText !== "") {
			filename = filename + " " + businessUnitText;
		}
		if (placeOfExpenditureText !== "") {
			filename = filename + " " + placeOfExpenditureText;
		}
		filename = filename + " Products.xlsx";

		var excelBuffer = await workbook.xlsx.writeBuffer();		
		var blob = new Blob([excelBuffer], { type: 'application/octet-stream' });	
		saveAs(blob, filename);
	}
	
	downloadPartnersFindPartnerObj(partnerArray, sellerNameERP1) {
		
		var i;
		var itemObj;
		var sellerNameERP2;
		
		for(i=0; i<partnerArray.length; i++) {
			itemObj = partnerArray[i];
			sellerNameERP2 = itemObj.name;
			if (sellerNameERP1 === sellerNameERP2) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	 downloadPartnersAddBillsToWorksheet(jsonObj, partnerName, worksheet3, row) {

		var i;
		var itemObj;
		var language = this.props.language;
		var sellerNameERP;
		var cellRef;
		var cellObj;
		var fillObj = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: 'EEEEEE' },
		};
	 
		cellRef = "A" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "billID";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'right' };
		
		cellRef = "B" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "date";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "C" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "priceTotalERP";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'right' };
		
		cellRef = "D" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "priceTotalAI";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'right' };
		
		cellRef = "E" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "discount";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'right' };
		
		cellRef = "F" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "konto";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "G" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "kontoTitle";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "H" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "businessUnit";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		cellObj.alignment = { horizontal: 'right' };
		
		cellRef = "I" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "businessUnitText";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "J" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "placeOfExpenditure";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		cellRef = "K" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "placeOfExpenditureText";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;

		cellRef = "L" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "paymentReferenceNumber";
		cellObj.font = { bold: true };
		cellObj.fill = fillObj;
		
		row ++;
		
		var year;
		var monthERP;
		var dayERP;
		var dateString = "";
		var businessUnitText = "";
		var placeOfExpenditureText = "";
		var row1 = row;
		var priceTotalERP = "";
		var priceTotal = "";
		var discount = "";

		for(i=0; i<jsonObj.length; i++) {

			itemObj = jsonObj[i];
			sellerNameERP = itemObj.sellerNameERP;
			
			year = itemObj.year;
			monthERP = itemObj.monthERP;
			dayERP = itemObj.dayERP;
			dateString = dayERP + "." + monthERP + "." + year;

			if (partnerName === sellerNameERP) {

				businessUnitText = "";
				if (itemObj.businessUnit !== 0) {
					businessUnitText = this.getBusinessUnitText(itemObj.businessUnit);
				}
				placeOfExpenditureText = "";
				if (itemObj.placeOfExpenditure !== "") {
					placeOfExpenditureText = this.getPlaceOfExpenditureText(itemObj.placeOfExpenditure);
				}

				priceTotalERP = itemObj.priceTotalERP;
				priceTotal = itemObj.priceTotal;
				discount = itemObj.priceTotal;

				if (language === "hr") {
					priceTotalERP = this.convertFloatToValueWithColon(priceTotalERP);
					priceTotal = this.convertFloatToValueWithColon(priceTotal);
					discount = this.convertFloatToValueWithColon(discount);
				}

				cellRef = "A" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = itemObj.billID;

				cellRef = "B" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = dateString;

				cellRef = "C" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = priceTotalERP;
				if (language === "hr") {
					cellObj.numFmt = '#.##0,00 "€"';
				} else {
					cellObj.numFmt = '#,##0.00 "€"';
				}

				cellRef = "D" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = priceTotal;
				if (language === "hr") {
					cellObj.numFmt = '#.##0,00 "€"';
				} else {
					cellObj.numFmt = '#,##0.00 "€"';
				}

				cellRef = "E" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = discount;
				if (language === "hr") {
					cellObj.numFmt = '#.##0,00 "€"';
				} else {
					cellObj.numFmt = '#,##0.00 "€"';
				}

				cellRef = "F" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = itemObj.konto;

				cellRef = "G" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = itemObj.kontoTitle;

				cellRef = "H" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = itemObj.businessUnit;

				cellRef = "I" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = businessUnitText;

				cellRef = "J" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = itemObj.placeOfExpenditure;				

				cellRef = "K" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = placeOfExpenditureText;

				cellRef = "L" + row;
				cellObj = worksheet3.getCell(cellRef);
				cellObj.value = itemObj.paymentReferenceNumber;

				row ++;

			}
		}

		var row2 = row - 1;
		var formulaString = "";

		cellRef = "A" + row;
		cellObj = worksheet3.getCell(cellRef);
		cellObj.value = "Total:";
		cellObj.font = { bold: true };

		cellRef = "C" + row;
		cellObj = worksheet3.getCell(cellRef);
		formulaString = "SUM(C" + row1 + ":C" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };
		if (language === "hr") {
			cellObj.numFmt = '#.##0,00 "€"';
		} else {
			cellObj.numFmt = '#,##0.00 "€"';
		}

		cellRef = "D" + row;
		cellObj = worksheet3.getCell(cellRef);
		formulaString = "SUM(D" + row1 + ":D" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };
		if (language === "hr") {
			cellObj.numFmt = '#.##0,00 "€"';
		} else {
			cellObj.numFmt = '#,##0.00 "€"';
		}

		cellRef = "E" + row;
		cellObj = worksheet3.getCell(cellRef);
		formulaString = "SUM(E" + row1 + ":E" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };
		if (language === "hr") {
			cellObj.numFmt = '#.##0,00 "€"';
		} else {
			cellObj.numFmt = '#,##0.00 "€"';
		}

		row ++;
		row ++;

		return [worksheet3, row];
	 }

	worksheetAddFilters(worksheet) {

		var searchText = this.state.searchText;
		var minPriceTotal = this.state.minPriceTotal;
		var businessUnitID = this.state.businessUnitID;
		var placeExpenditureID = this.state.placeExpenditureID;
		var selectedYear = this.props.selectedYear;
		var email = this.props.email;
		var fullName = this.props.fullName;
		var businessUnitText = this.getBusinessUnitText(businessUnitID);
		var placeOfExpenditureText = this.getPlaceOfExpenditureText(placeExpenditureID);
		var allBillsYN = this.props.allBillsYN;
		var dashboardName = this.props.dashboardName;
		
		var today = new Date();
		var year = today.getFullYear();
		var month = today.getMonth() + 1;
		var day = today.getDate();		
		var dateString = day + "." + month + "." + year;
		var hour = today.getHours();
		var minute = today.getMinutes();
		var second = today.getSeconds();
		var timeString = hour + ":" + this.props.getTwoDigits(minute) + ":" + this.props.getTwoDigits(second);

		var row = 1;
		var cellObj;

		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Search:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = searchText;

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Min total price:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = minPriceTotal;
		cellObj.alignment = { horizontal: 'left' };

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Business unit:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		
		cellObj.value = "";
		if (allBillsYN) {
			if (businessUnitID !== 0) {
				cellObj.value = businessUnitID + " " + businessUnitText;
			}
		} else {
			cellObj.value = dashboardName;
		}
		
		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Place of expenditure:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		if (placeOfExpenditureText === "") {
			cellObj.value = "";
		} else {
			cellObj.value = placeExpenditureID + " " + placeOfExpenditureText;
		}

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Seleted year:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = selectedYear;
		cellObj.alignment = { horizontal: 'left' };

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "User:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = fullName + " (" + email + ")";

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Date:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = dateString;

		row ++;
		
		cellObj = worksheet.getCell("A" + row);
		cellObj.value = "Time:";
		cellObj.font = { bold: true };

		cellObj = worksheet.getCell("B" + row);
		cellObj.value = timeString;

		row ++;
		
	}
	
	async handleDownloadPartners() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			this.props.showToast("No partners found");
			return;
		}

		var i;
		var itemObj1;
		var length = jsonObj.length;
		var sellerNameERP;
		var sellerName;
		var priceTotalERP;
		var priceTotal;
		var partnerArray = [];
		var itemObj2;
		var row = 0;
		var formulaString = "";

		for(i=0; i<length; i++) {

			itemObj1 = jsonObj[i];
			sellerNameERP = itemObj1.sellerNameERP;
			sellerName = itemObj1.sellerName;
			priceTotalERP = itemObj1.priceTotalERP;
			priceTotal = itemObj1.priceTotal;

			itemObj2 = this.downloadPartnersFindPartnerObj(partnerArray, sellerNameERP);

			if (itemObj2 === null) {
				itemObj2 = {};
				itemObj2.name = sellerNameERP;
				itemObj2.erpTotal = priceTotalERP;
				itemObj2.aiTotal = priceTotal;
				itemObj2.billCount = 1;
				partnerArray.push(itemObj2);
			} else {
				itemObj2.erpTotal += priceTotalERP;
				itemObj2.aiTotal += priceTotal;
				itemObj2.billCount ++;
			}
		}

		// malo kompliciranije napravit "." u "," zbog sorta --> nakon sorta
		var language = this.props.language;
		
		// deep copy using JSON methods
		var partnerArray1 = JSON.parse(JSON.stringify(partnerArray));

		partnerArray1.sort((a, b) => a.name.localeCompare(b.name));		
		for(i=0; i<partnerArray1.length; i++) {
			itemObj1 = partnerArray1[i];
			itemObj1.erpTotal = Math.round(itemObj1.erpTotal * 100) / 100;
			itemObj1.aiTotal = Math.round(itemObj1.aiTotal * 100) / 100;
			if (language === "hr") {
				itemObj1.erpTotal = this.convertFloatToValueWithColon(itemObj1.erpTotal);
				itemObj1.aiTotal = this.convertFloatToValueWithColon(itemObj1.aiTotal);
			}
		}

		var cellRef;
		var cellObj;
		var workbook = new ExcelJS.Workbook();

		var worksheet1 = workbook.addWorksheet("Partners");
		var columns = Object.keys(partnerArray1[0]).map((key) => ({ header: key, key: key }));	
		worksheet1.columns = columns;
		worksheet1.columns = [
			{ key: 'A', width: 40 },
			{ key: 'B', width: 15 },
			{ key: 'C', width: 15 },
			{ key: 'D', width: 15 },
		];
		partnerArray1.forEach((itemObj) => {
			worksheet1.addRow(itemObj);
		});
	
		cellObj = worksheet1.getCell("A1");
		cellObj.font = { bold: true };

		cellObj = worksheet1.getCell("B1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("C1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet1.getCell("D1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		row = partnerArray1.length + 2;

		cellRef = "A" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "Total:";
		cellObj.font = { bold: true };

		cellRef = "B" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(B2:B" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "C" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(C2:C" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "D" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(D2:D" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };
		
		for(i=2; i<=partnerArray1.length+2; i++) {
			cellRef = "B" + i;
			cellObj = worksheet1.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		for(i=2; i<=partnerArray1.length+2; i++) {
			cellRef = "C" + i;
			cellObj = worksheet1.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		var partnerArray2 = JSON.parse(JSON.stringify(partnerArray));
		partnerArray2.sort((a, b) => b.erpTotal - a.erpTotal);
		for(i=0; i<partnerArray2.length; i++) {
			itemObj1 = partnerArray2[i];
			itemObj1.erpTotal = Math.round(itemObj1.erpTotal * 100) / 100;
			itemObj1.aiTotal = Math.round(itemObj1.aiTotal * 100) / 100;
			if (language === "hr") {
				itemObj1.erpTotal = this.convertFloatToValueWithColon(itemObj1.erpTotal);
				itemObj1.aiTotal = this.convertFloatToValueWithColon(itemObj1.aiTotal);
			}
		}
		var worksheet2 = workbook.addWorksheet("Partners - sorted by total");
		var columns = Object.keys(partnerArray2[0]).map((key) => ({ header: key, key: key }));	
		worksheet2.columns = columns;
		worksheet2.columns = [
			{ key: 'A', width: 40 },
			{ key: 'B', width: 15 },
			{ key: 'C', width: 15 },
			{ key: 'D', width: 15 },
		];
		partnerArray2.forEach((itemObj) => {
			worksheet2.addRow(itemObj);
		});
		
		cellObj = worksheet2.getCell("A1");
		cellObj.font = { bold: true };

		cellObj = worksheet2.getCell("B1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet2.getCell("C1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellObj = worksheet2.getCell("D1");
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		row = partnerArray1.length + 2;

		cellRef = "A" + row;
		cellObj = worksheet2.getCell(cellRef);
		cellObj.value = "Total:";
		cellObj.font = { bold: true };

		cellRef = "B" + row;
		cellObj = worksheet2.getCell(cellRef);
		formulaString = "SUM(B2:B" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "C" + row;
		cellObj = worksheet2.getCell(cellRef);
		formulaString = "SUM(C2:C" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		cellRef = "D" + row;
		cellObj = worksheet2.getCell(cellRef);
		formulaString = "SUM(D2:D" + (partnerArray1.length+1) + ")";
		cellObj.value = { formula: formulaString };
		cellObj.font = { bold: true };

		for(i=2; i<=partnerArray2.length+2; i++) {
			cellRef = "B" + i;
			cellObj = worksheet2.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		for(i=2; i<=partnerArray2.length+2; i++) {
			cellRef = "C" + i;
			cellObj = worksheet2.getCell(cellRef);
			if (language === "hr") {
				cellObj.numFmt = '#.##0,00 "€"';
			} else {
				cellObj.numFmt = '#,##0.00 "€"';
			}
		}

		var worksheet3 = workbook.addWorksheet("Bills");
		
		worksheet3.columns = [
			{ key: 'A', width: 10 },
			{ key: 'B', width: 10 },
			{ key: 'C', width: 15 },
			{ key: 'D', width: 15 },
			{ key: 'E', width: 10 },
			{ key: 'F', width: 10 },
			{ key: 'G', width: 30 },
			{ key: 'H', width: 15 },
			{ key: 'I', width: 20 },
			{ key: 'J', width: 15 },
			{ key: 'K', width: 30 },
			{ key: 'L', width: 30 },
		];

		var partnerName;
		row = 1;

		for(i=0; i<partnerArray1.length; i++) {
			itemObj1 = partnerArray1[i];
			partnerName = itemObj1.name;
			cellRef = "A" + row;  // cell reference like 'A1', 'A2'
			cellObj = worksheet3.getCell(cellRef);
			cellObj.value = partnerName;
			cellObj.font = { 
				bold: true,
				color: { argb: '0000FF' },
			};	
			row ++;
			[worksheet3, row] = this.downloadPartnersAddBillsToWorksheet(jsonObj, partnerName, worksheet3, row);
		}
		
		var worksheet4 = workbook.addWorksheet("Filters");
		worksheet4.columns = [
			{ key: 'A', width: 20 },
			{ key: 'B', width: 50 },
		];
		this.worksheetAddFilters(worksheet4);
	
		var excelBuffer = await workbook.xlsx.writeBuffer();		
		var blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
	
		var dateString = this.getTodayDateString();

		var businessUnitID = this.state.businessUnitID;
		var placeExpenditureID = this.state.placeExpenditureID;
		
		var businessUnitText = "";
		if (businessUnitID !== 0) {
			businessUnitText = this.getBusinessUnitText(businessUnitID);
		}
		var placeOfExpenditureText = "";
		if (placeExpenditureID !== "") {
			placeOfExpenditureText = this.getPlaceOfExpenditureText(placeExpenditureID);
		}
		
		var allBillsYN = this.props.allBillsYN;
		var dashboardName = this.props.dashboardName;
		
		var filename = dateString;
		if (!allBillsYN) {
			filename = filename + " " + dashboardName;
		}
		if (businessUnitText !== "") {
			filename = filename + " " + businessUnitText;
		}
		if (placeOfExpenditureText !== "") {
			filename = filename + " " + placeOfExpenditureText;
		}
		filename = filename + " Partners.xlsx";
		saveAs(blob, filename);
	}
	
	downloadPlaceOfExpenditureFindBusinessUnitObj(businessUnitArray, businessUnit1) {
		
		var i;
		var itemObj;
		var businessUnit2;
		
		for(i=0; i<businessUnitArray.length; i++) {
			itemObj = businessUnitArray[i];
			businessUnit2 = itemObj.businessUnit;
			if (businessUnit1 === businessUnit2) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	downloadPlaceOfExpenditureFindPlaceOfExpenditureObj(placeOfExpenditureArray, placeOfExpenditure1) {
		
		var i;
		var itemObj;
		var placeOfExpenditure2;
		
		for(i=0; i<placeOfExpenditureArray.length; i++) {
			itemObj = placeOfExpenditureArray[i];
			placeOfExpenditure2 = itemObj.placeOfExpenditure;
			if (placeOfExpenditure1 === placeOfExpenditure2) {
				return itemObj;
			}
		}
		
		return null;
	}
	
	downloadPlaceOfExpenditureGetPlaceOfExpenditureArray(jsonObj, businessUnit1) {

		var i;
		var itemObj1;
		var itemObj2;
		var businessUnit2;
		var placeOfExpenditure;
		var placeOfExpenditureArray = [];
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj1 = jsonObj[i];
			businessUnit2 = itemObj1.businessUnit;
			if (businessUnit1 === businessUnit2) {
				placeOfExpenditure = itemObj1.placeOfExpenditure;
				itemObj2 = this.downloadPlaceOfExpenditureFindPlaceOfExpenditureObj(placeOfExpenditureArray, placeOfExpenditure);
				if (itemObj2 === null) {
					itemObj2 = {};
					itemObj2.placeOfExpenditure = placeOfExpenditure;
					itemObj2.placeOfExpenditureText = this.getPlaceOfExpenditureText(placeOfExpenditure);
					itemObj2.sumArray = [0,0,0,0,0,0,0,0,0,0,0,0,0];
					placeOfExpenditureArray.push(itemObj2);
				}
			}
		}
		
		placeOfExpenditureArray.sort((a, b) => {

			var str;
			var placeOfExpenditure1 = a.placeOfExpenditure;
			var placeOfExpenditure2 = b.placeOfExpenditure;
			var index1 = placeOfExpenditure1.indexOf(".");
			var index2 = placeOfExpenditure2.indexOf(".");
			var a1;
			var b1;

			if (index1 === -1) {
				a1 = parseInt(placeOfExpenditure1);
			} else {
				str = placeOfExpenditure1.substring(index1+1, placeOfExpenditure1.length);
				a1 = parseInt(str);
			}

			if (index2 === -1) {
				b1 = parseInt(placeOfExpenditure2);
			} else {
				str = placeOfExpenditure2.substring(index2+1, placeOfExpenditure2.length);
				b1 = parseInt(str);
			}

			return a1 - b1;
		});

		return placeOfExpenditureArray;
	}

	downloadPlaceOfExpenditureWriteOnePlaceOfExpenditure(worksheet1, itemObj, row) {

		var language = this.props.language;
		var placeOfExpenditure = itemObj.placeOfExpenditure;
		var placeOfExpenditureText = itemObj.placeOfExpenditureText;
		
		var cellRef;
		var cellObj;
		var numFmt;

		if (language === "hr") {
			numFmt = '#.##0,00 "€"';
		} else {
			numFmt = '#,##0.00 "€"';
		}

		cellRef = "B" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = placeOfExpenditure + " " + placeOfExpenditureText;
		
		var sumArray = itemObj.sumArray;		
		var formulaString = "";

		cellRef = "C" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(D" + row + ":O" + row + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;
		
		cellRef = "D" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[1];
		cellObj.numFmt = numFmt;
		
		cellRef = "E" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[2];
		cellObj.numFmt = numFmt;
		
		cellRef = "F" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[3];
		cellObj.numFmt = numFmt;
		
		cellRef = "G" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[4];
		cellObj.numFmt = numFmt;
		
		cellRef = "H" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[5];
		cellObj.numFmt = numFmt;
		
		cellRef = "I" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[6];
		cellObj.numFmt = numFmt;
		
		cellRef = "J" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[7];
		cellObj.numFmt = numFmt;
		
		cellRef = "K" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[8];
		cellObj.numFmt = numFmt;
		
		cellRef = "L" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[9];
		cellObj.numFmt = numFmt;
		
		cellRef = "M" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[10];
		cellObj.numFmt = numFmt;
		
		cellRef = "N" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[11];
		cellObj.numFmt = numFmt;
		
		cellRef = "O" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = sumArray[12];
		cellObj.numFmt = numFmt;
		
		if (sumArray[0] > 0) {
			cellRef = "P" + row;
			cellObj = worksheet1.getCell(cellRef);
			cellObj.value = sumArray[0];
			cellObj.numFmt = numFmt;
		}
		
		row ++;

		return row;
	}
	
	downloadPlaceOfExpenditureWriteOneBusinessUnit(worksheet1, itemObj1, row) {

		var language = this.props.language;
		var businessUnit = itemObj1.businessUnit;
		var businessUnitText = itemObj1.businessUnitText;
		var placeOfExpenditureArray = itemObj1.placeOfExpenditureArray;
		var selectedYear = this.props.selectedYear;

		var cellRef;
		var cellObj;
		var formulaString;
		var numFmt;

		if (language === "hr") {
			numFmt = '#.##0,00 "€"';
		} else {
			numFmt = '#,##0.00 "€"';
		}

		cellRef = "A" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = businessUnit + " " + businessUnitText;
		cellObj.font = { bold: true };

		cellRef = "C" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = selectedYear;
		cellObj.font = { bold: true };

		cellRef = "D" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "01/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "E" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "02/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "F" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "03/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "G" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "04/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "H" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "05/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "I" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "06/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "J" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "07/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "K" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "08/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "L" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "09/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "M" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "10/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "N" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "11/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		cellRef = "O" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "12/" + selectedYear;
		cellObj.font = { bold: true };
		cellObj.alignment = { horizontal: 'right' };

		row ++;

		var i;
		var itemObj2;
		var row1 = row;
		for(i=0; i<placeOfExpenditureArray.length; i++) {
			itemObj2 = placeOfExpenditureArray[i];
			row = this.downloadPlaceOfExpenditureWriteOnePlaceOfExpenditure(worksheet1, itemObj2, row);
		}
		var row2 = row-1;

		cellRef = "B" + row;
		cellObj = worksheet1.getCell(cellRef);
		cellObj.value = "Total:";
		cellObj.font = { bold: true };

		// niksa
		cellRef = "C" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(C" + row1 + ":C" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "D" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(D" + row1 + ":D" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "E" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(E" + row1 + ":E" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "F" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(F" + row1 + ":F" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "G" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(G" + row1 + ":G" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "H" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(H" + row1 + ":H" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "I" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(I" + row1 + ":I" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "J" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(J" + row1 + ":J" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "K" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(K" + row1 + ":K" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "L" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(L" + row1 + ":L" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "M" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(M" + row1 + ":M" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "N" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(N" + row1 + ":N" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		cellRef = "O" + row;
		cellObj = worksheet1.getCell(cellRef);
		formulaString = "SUM(O" + row1 + ":O" + row2 + ")";
		cellObj.value = { formula: formulaString };
		cellObj.numFmt = numFmt;

		row ++;
		row ++;

		return row;
	}

	async handleDownloadPlaceOfExpenditure() {

		var jsonObj = this.state.jsonObj;
		if (jsonObj === null) {
			return;
		}

		var i;
		var itemObj1;
		var itemObj2;
		var itemObj3;
		var businessUnitArray = [];
		var businessUnit;
		var businessUnitText;
		var placeOfExpenditureArray;

		for(i=0; i<jsonObj.length; i++) {
			itemObj1 = jsonObj[i];
			businessUnit = itemObj1.businessUnit;
			itemObj2 = this.downloadPlaceOfExpenditureFindBusinessUnitObj(businessUnitArray, businessUnit);
			if (itemObj2 === null) {
				itemObj2 = {};
				itemObj2.businessUnit = businessUnit;
				itemObj2.businessUnitText = this.getBusinessUnitText(businessUnit);
				businessUnitArray.push(itemObj2);
			}
		}
		
		businessUnitArray.sort((a, b) => a.businessUnit - b.businessUnit);
		
		for(i=0; i<businessUnitArray.length; i++) {
			itemObj1 = businessUnitArray[i];
			businessUnit = itemObj1.businessUnit;
			placeOfExpenditureArray = this.downloadPlaceOfExpenditureGetPlaceOfExpenditureArray(jsonObj, businessUnit);
			itemObj1.placeOfExpenditureArray = placeOfExpenditureArray;
		}

		var priceTotalERP;
		var monthERP;
		var placeOfExpenditure;

		for(i=0; i<jsonObj.length; i++) {
			itemObj1 = jsonObj[i];
			priceTotalERP = itemObj1.priceTotalERP;
			monthERP = itemObj1.monthERP;
			businessUnit = itemObj1.businessUnit;
			placeOfExpenditure = itemObj1.placeOfExpenditure;
			itemObj2 = this.downloadPlaceOfExpenditureFindBusinessUnitObj(businessUnitArray, businessUnit);
			if (itemObj2 !== null) {
				placeOfExpenditureArray = itemObj2.placeOfExpenditureArray;
				itemObj3 = this.downloadPlaceOfExpenditureFindPlaceOfExpenditureObj(placeOfExpenditureArray, placeOfExpenditure);
				if (itemObj3 !== null) {
					if ((monthERP < 1) || (monthERP > 12)) {
						itemObj3.sumArray[0] += priceTotalERP;
					} else {
						itemObj3.sumArray[monthERP] += priceTotalERP;
					}
				}
			}
		}
		
		var cellRef;
		var cellObj;
		var workbook = new ExcelJS.Workbook();

		var worksheet1 = workbook.addWorksheet("Place of Expenditure");
		worksheet1.columns = [
			{ key: 'A', width: 12 },
			{ key: 'B', width: 25 },
			{ key: 'C', width: 15 },
			{ key: 'D', width: 12 },
			{ key: 'E', width: 12 },
			{ key: 'F', width: 12 },
			{ key: 'G', width: 12 },
			{ key: 'H', width: 12 },
			{ key: 'I', width: 12 },
			{ key: 'J', width: 12 },
			{ key: 'K', width: 12 },
			{ key: 'L', width: 12 },
			{ key: 'M', width: 12 },
			{ key: 'N', width: 12 },
			{ key: 'O', width: 12 },
		];
		
		var row = 1;

		for(i=0; i<businessUnitArray.length; i++) {
			itemObj1 = businessUnitArray[i];
			row = this.downloadPlaceOfExpenditureWriteOneBusinessUnit(worksheet1, itemObj1, row);
		}

		var worksheet2 = workbook.addWorksheet("Filters");
		worksheet2.columns = [
			{ key: 'A', width: 20 },
			{ key: 'B', width: 50 },
		];
		this.worksheetAddFilters(worksheet2);

		var dateString = this.getTodayDateString();

		var businessUnitID = this.state.businessUnitID;
		var placeExpenditureID = this.state.placeExpenditureID;
		
		var businessUnitText = "";
		if (businessUnitID !== 0) {
			businessUnitText = this.getBusinessUnitText(businessUnitID);
		}
		var placeOfExpenditureText = "";
		if (placeExpenditureID !== "") {
			placeOfExpenditureText = this.getPlaceOfExpenditureText(placeExpenditureID);
		}
		
		var allBillsYN = this.props.allBillsYN;
		var dashboardName = this.props.dashboardName;
		
		var filename = dateString;
		if (!allBillsYN) {
			filename = filename + " " + dashboardName;
		}
		if (businessUnitText !== "") {
			filename = filename + " " + businessUnitText;
		}
		if (placeOfExpenditureText !== "") {
			filename = filename + " " + placeOfExpenditureText;
		}
		filename = filename + " Place of Expenditure.xlsx";

		var excelBuffer = await workbook.xlsx.writeBuffer();		
		var blob = new Blob([excelBuffer], { type: 'application/octet-stream' });	
		saveAs(blob, filename);
	}
	
	handleBillsMissing() {
		
		var jsonObj = this.state.jsonObj;
		var selectedYear = this.props.selectedYear;
		var i;
		var itemObj;
		var billID;
		var billIDArray = [];
		var missingBillIDArray = [];
		var missingPDFArray = [];
		var pdfYN;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			billID = parseInt(itemObj.billID);
			billIDArray.push(billID);
		}
		
		billIDArray.sort((a, b) => a - b);
		
		var maxBillID = billIDArray[billIDArray.length-1];
		for (i=1; i<maxBillID; i++) {
			if (!billIDArray.includes(i)) {
				missingBillIDArray.push(i);
			}
		}

		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			billID = parseInt(itemObj.billID);
			pdfYN = itemObj.pdfYN;
			if (!pdfYN) {
				missingPDFArray.push(billID);
			}
		}

		missingPDFArray.sort((a, b) => a - b);
		
		var newline = "\n";
		var outputText = "";
		
		outputText = outputText + "Year: " + selectedYear + newline;
		outputText = outputText + "Total bill count: " + billIDArray.length + newline;
		outputText = outputText + "Missing bills count: " + missingBillIDArray.length + newline;
		outputText = outputText + "Missing pdfs count: " + missingPDFArray.length + newline;
		outputText = outputText + newline;
		
		outputText = outputText + "MISSING BILL IDS:" + newline;
		for (i=0; i<missingBillIDArray.length; i++) {
			billID = missingBillIDArray[i];
			outputText = outputText + billID + newline;
		}
		outputText = outputText + newline;

		outputText = outputText + "MISSING PDFS:" + newline;
		for (i=0; i<missingPDFArray.length; i++) {
			billID = missingPDFArray[i];
			outputText = outputText + billID + newline;
		}

		var dateString = this.getTodayDateString();
		var filename = dateString + " missing bills.txt";

		var blob = new Blob([outputText], { type: "text/plain;charset=utf-8" });	
		saveAs(blob, filename);
    }
	
	renderDownloadButtons() {

		var showAllYN = this.state.showAllYN;
		if (!showAllYN) {
			return null;
		}

		var allBillsYN = this.props.allBillsYN;

		return (
			<div>
				<hr/>
				<div style={{height: "5px"}}></div>
				<div className="ReportExpensesDownloadContainter">
					<div className="ReportExpensesDownloadLink" onClick={() => this.handleDownloadProducts()}>
						Download products
					</div>
				</div>
				<div style={{height: "5px"}}></div>
				<div className="ReportExpensesDownloadContainter">
					<div className="ReportExpensesDownloadLink" onClick={() => this.handleDownloadPartners()}>
						Download partners
					</div>
				</div>
				<div style={{height: "5px"}}></div>
				<div className="ReportExpensesDownloadContainter">
					<div className="ReportExpensesDownloadLink" onClick={() => this.handleDownloadPlaceOfExpenditure()}>
						Download place of expenditure
					</div>
				</div>
				{allBillsYN && (
					<div className="ReportExpensesDownloadContainter">
						<div className="ReportExpensesDownloadLink" onClick={() => this.handleBillsMissing()}>
							Bills missing
						</div>
					</div>
				)}
			</div>
		);
	}
		
	render() {
		
		return (
			<div>
				<div style={{height: "5px"}}></div>
				{this.renderSearch()}
				<div style={{height: "5px"}}></div>
				{this.renderSelectedBills()}
				<div style={{height: "5px"}}></div>
				{this.renderPrevNext()}
				<div style={{height: "5px"}}></div>
				{this.renderBillsMonthly()}
				<div style={{height: "5px"}}></div>
				{this.renderDownloadButtons()}
				<div style={{height: "50px"}}></div>
			</div>
		);
	}
}

export default ReportBillList;
