import React  from 'react';
import './App.css';
import './Report.css';
import DashboardLang from './DashboardLang.json';

class ReportOccupancyProgressCustomTooltip extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);		
	}

	getValueFromItemObj(itemObj) {
		var name = itemObj.dataKey;
		var payload = itemObj.payload;
		var value = payload[name];
		return value;
	}

	renderOneLine(index, itemObj, dateString1, dateString2) {

		var name = itemObj.dataKey;
		var color = itemObj.color;
		var payload = itemObj.payload;
		var value = this.getValueFromItemObj(itemObj);
		
		const divStyle = { color: color };

		var decimalPlaces = 0;
		var valueString = this.props.getEURString(value, decimalPlaces);
		
		var dateString = "";
		if (index === 0) {
			dateString = dateString1;
		} else {
			dateString = dateString2;
		}
		
		if (dateString === "") {
			return null;
		}

		return (
			<div key={index} style={divStyle}>{dateString}: {valueString}</div>
		);
	}
	
	render() {
		
		var currentYear = this.props.currentYear;
		var dataLastYear = this.props.dataLastYear;
		var dataCurrentYear = this.props.dataCurrentYear;		
		
		var active = this.props.active;
		var payload = this.props.payload;
		
		if (!active) {
			return null;
		}

		if (payload === null) {
			return null;
		}
		
		var itemObj = payload[0];
		var onePayload = itemObj.payload;
		var dayIndex = onePayload.day;
		var i;
		var jsxCode1;
		var jsxCode2;
		var jsxArray = [];
		var month1 = 0;
		var day1 = 0;
		var month2 = 0;
		var day2 = 0;
		var dateString1 = "";
		var dateString2 = "";
		var value0;
		var value1;
		var diffAmount;
		var percentage;
		var percentageClass;
		var percentageString;
		
		if (dayIndex < dataLastYear.length) {
			itemObj = dataLastYear[dayIndex];
			if (itemObj !== null) {
				month1 = itemObj.month;
				day1 = itemObj.day;
				dateString1 = day1 + "." + month1 + "." + (currentYear-1);
			}
		}

		if (dayIndex < dataCurrentYear.length) {
			itemObj = dataCurrentYear[dayIndex];
			if (itemObj !== null) {
				month2 = itemObj.month;
				day2 = itemObj.day;
				dateString2 = day2 + "." + month2 + "." + currentYear;
			}
		}

		for(i=0; i<payload.length; i++) {
			itemObj = payload[i];
			jsxCode1 = this.renderOneLine(i, itemObj, dateString1, dateString2);
			if (jsxCode1 !== null) {
				jsxArray.push(jsxCode1);
			}
		}
		
		if (jsxArray.length === 0) {
			return null;
		}
		
		jsxCode2 = null;
		if (jsxArray.length === 2) {
			itemObj = payload[0];
			value0 = this.getValueFromItemObj(itemObj);
			itemObj = payload[1];
			value1 = this.getValueFromItemObj(itemObj);
			diffAmount = value1 - value0;
			percentage = 0;
			if (value0 !== 0) {
				percentage = 100 * diffAmount / value0;
			}
			//percentage = Math.round(percentage * 100) / 100;
			percentage = Math.round(percentage);
			percentageString = percentage;
			percentageClass = "ReportOccupancyGraphPercentageGrey";
			if (percentage > 0) {
				percentageClass = "ReportOccupancyGraphPercentageGreen";
				percentageString = "+" + percentage;
			}
			if (percentage < 0) {
				percentageClass = "ReportOccupancyGraphPercentageRed";
				percentageString = percentage;
			}
			jsxCode2 = <div class={percentageClass}>{percentageString}%</div>
		}

		return (
			<div className="ReportOccupancyProgressCustomTooltip">
				<div>
					<div>{jsxArray}</div>
					<div>{jsxCode2}</div>
				</div>
			</div>
		);
	}
}

export default ReportOccupancyProgressCustomTooltip;

