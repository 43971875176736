import React  from 'react';
import './App.css';
import AppLang from './AppLang.json';

class About extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);
	}
	
	renderOneLine(dateString, infoText) {
		
		return (
			<div>
				<div className="AboutDateString">{dateString}</div>
				<div className="AboutInfoText">{infoText}</div>
				<div style={{height: "15px"}}></div>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div style={{height: "20px"}}></div>
				<div className="AboutTitle">{this.props.getLang(AppLang, "about")}</div>
				<div style={{height: "20px"}}></div>
				{/*this.renderOneLine("Android mob app", "https://storageapi.plurato.com/apk/xxxxx.apk (NOT AVAILABLE)")*/}
				{this.renderOneLine("1.2.2025.", "Bank statements / izvodi od banaka")}
				{this.renderOneLine("20.12.2024.", "Bills / racuni")}
				{this.renderOneLine("25.1.2024.", "Reports / izvjestaji + RPA auto import from Milenij software")}
				{this.renderOneLine("15.1.2024.", "Compare / usporedi")}
				{this.renderOneLine("28.12.2023.", "Recent / nedavno")}
				{this.renderOneLine("14.10.2023.", "Only some users can see expenses")}
				{this.renderOneLine("9.8.2023.", "Expenses - monthly, yearly and edit")}
				{this.renderOneLine("27.7.2023.", "Monthly view - percentage change from previous year")}
				{this.renderOneLine("12.7.2023.", "Section 'Yearly View' implemented")}
				{this.renderOneLine("10.7.2023.", "First release")}
				{this.renderOneLine("3.7.2023.", "Development started")}
			</div>
		);
	}
}

export default About;

