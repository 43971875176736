import React  from 'react';
import './App.css';
import './Report.css';
import ReportLang from './ReportLang.json';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import ReportOccupancyProgressCustomTooltip from './ReportOccupancyProgressCustomTooltip.js';

class ReportOccupancyProgress extends React.Component {

	constructor(props) {
		
		super(props);
		
		this.state = {
			width: 0,
			height: 0,
			currentYear: 0,
			jsonObjLastYear: null,
			jsonObjCurrentYear: null,
			dataLastYear: null,
			dataCurrentYear: null,
			colorArray: ["grey", "#82ca9d", "orange", "blue", "green", "red", "grey", "#ca829d", "#82ca9d", "#8884d8"],
		}
		
		this.updateDimensions = this.updateDimensions.bind(this);
		this.createDataFromJsonObj = this.createDataFromJsonObj.bind(this);
	}
	
	async componentDidMount() {

		var today = new Date();
		var currentYear = today.getFullYear();

		this.updateDimensions();
		window.addEventListener('resize', this.updateDimensions);

		var dashboardID = this.props.dashboardID;
		var jsonObjLastYear = await this.serverGetRoomsWholeYearSummary(dashboardID, currentYear-1);
		var jsonObjCurrentYear = await this.serverGetRoomsWholeYearSummary(dashboardID, currentYear);
		var dataLastYear = this.createDataFromJsonObj(currentYear-1, jsonObjLastYear);
		var dataCurrentYear = this.createDataFromJsonObj(currentYear, jsonObjCurrentYear);

		this.setState({
			currentYear: currentYear,
			jsonObjLastYear: jsonObjLastYear,
			jsonObjCurrentYear: jsonObjCurrentYear,
			dataLastYear: dataLastYear,
			dataCurrentYear: dataCurrentYear,
		});
	}

	componentWillUnmount() {
		document.removeEventListener('resize', this.updateDimensions, true);
	};
	
	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};
	
	async serverGetRoomsWholeYearSummary(dashboardID, year) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getroomswholeyearsummary?dashboardid=" + dashboardID + "&year=" + year;
		
		var res = await fetch(url);
		var jsonObj = await res.json();

		return jsonObj;
	}
	
	findItemFromMonthDay(jsonObj, month1, day1) {
		
		var i;
		var itemObj;
		var month2;
		var day2;
		var dayDiff;
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			month2 = itemObj.month;
			day2 = itemObj.day;
			if ((month1 === month2) && (day1 === day2)) {
				return itemObj;
			}
		}

		return null;
	}
	
	createDataFromJsonObj(year, jsonObj) {

		var start = new Date(year, 0, 1); // January 1st
		var end = new Date(year, 11, 31); // December 31st
		var date = [];
		var d;
		var i;
		var itemObj;
		var month;
		var day;
		var data = [];

		i = 0;
		for (d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {

			month = d.getMonth()+1;
			day = d.getDate();
			itemObj = this.findItemFromMonthDay(jsonObj, month, day);
			
			data.push(itemObj);
			
			i ++;
		}
		
		return data;
	}
	
	renderLineChart() {

		var currentYear = this.state.currentYear;
		var jsonObjLastYear = this.state.jsonObjLastYear;
		var jsonObjCurrentYear = this.state.jsonObjCurrentYear;
		var dataLastYear = this.state.dataLastYear;
		var dataCurrentYear = this.state.dataCurrentYear;
		if (dataCurrentYear === null) {
			return null;
		}

		var i;
		var width1 = window.innerWidth - 10;
		var height1 = window.innerHeight - 200;
		var data = [];
		var oneRow;
		var itemObj;
		var roomRevenue1;
		var roomRevenue2;

		var length = dataLastYear.length;
		if (dataCurrentYear.length > length) {
			length = dataCurrentYear.length;
		}

		for(i=0; i<length; i++) {
			oneRow = {};
			oneRow.day = i;
			roomRevenue1 = 0;
			if (i < dataLastYear.length) {
				itemObj = dataLastYear[i];
				if (itemObj !== null) {
					roomRevenue1 = itemObj.roomRevenue
				}
			}
			roomRevenue2 = 0;
			if (i < dataCurrentYear.length) {
				itemObj = dataCurrentYear[i];
				if (itemObj !== null) {
					roomRevenue2 = itemObj.roomRevenue
				}
			}
			oneRow[currentYear-1] = roomRevenue1;
			oneRow[currentYear] = roomRevenue2;
			data.push(oneRow);
		}

		var colorArray = this.state.colorArray;
		var color1 = colorArray[0];
		var color2 = colorArray[1];

		return (
			<div>
				<LineChart
					width={width1}
					height={height1}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<Tooltip content={
						<ReportOccupancyProgressCustomTooltip
							currentYear={currentYear}
							dataLastYear={dataLastYear}
							dataCurrentYear={dataCurrentYear}
							getLang={this.props.getLang}
							getEURString={this.props.getEURString}								
							getDayOfWeek={this.props.getDayOfWeek}
							getWeekdayString={this.props.getWeekdayString}
							getWeekdayStringShort={this.props.getWeekdayStringShort}
						/>}
						cursor={false} 
					/>
					<XAxis dataKey="dayIndex" />
					<YAxis />
							
					<Line type="monotone" dataKey={currentYear-1} stroke={color2}/>
					<Line type="monotone" dataKey={currentYear} stroke={color1}/>
				</LineChart>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div style={{height: "20px"}}></div>
				{this.renderLineChart()}
			</div>
		);
	}
}

export default ReportOccupancyProgress;

